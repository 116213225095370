.faq-page {
  .faq {
    &-card-header {
      &.collapse {
        display: block;
      }
    }
    &-card-body {
      display: none;
      &.show {
        display: block;
      }
    }
  }
}
