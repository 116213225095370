@mixin font-set($family, $file, $weight, $style) {
    @font-face {
        font-family: $family;
        font-weight: $weight;
        font-style: $style;
        src:  url('/assets/site/fonts/#{$file}.woff2') format('woff2'),
              url('/assets/site/fonts/#{$file}.woff') format('woff'),
              url('/assets/site/fonts/#{$file}.ttf') format('truetype');
        font-display: swap;
     }
}

@include font-set('Poppins', 'Poppins-ThinItalic', 100, italic);

// TODO: https://fontsource.org/fonts/poppins

// @font-face {
//     font-family: 'Poppins';
//     src: url('/assets/site/fonts/Poppins-Light.woff2') format('woff2'),
//     url('/assets/site/fonts/Poppins-Light.woff') format('woff'),
//     url('/assets/site/fonts/Poppins-Light.ttf') format('truetype');
//     font-weight: 300;
//     font-style: normal;
//     font-display: swap;
// }

@font-face {
    font-family: 'Poppins';
    src: url('/assets/site/fonts/Poppins-SemiBold.woff2') format('woff2'),
    url('/assets/site/fonts/Poppins-SemiBold.woff') format('woff'),
    url('/assets/site/fonts/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/assets/site/fonts/Poppins-SemiBoldItalic.woff2') format('woff2'),
    url('/assets/site/fonts/Poppins-SemiBoldItalic.woff') format('woff'),
    url('/assets/site/fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/assets/site/fonts/Poppins-Regular.woff2') format('woff2'),
    url('/assets/site/fonts/Poppins-Regular.woff') format('woff'),
    url('/assets/site/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/assets/site/fonts/Poppins-ExtraLight.woff2') format('woff2'),
    url('/assets/site/fonts/Poppins-ExtraLight.woff') format('woff'),
    url('/assets/site/fonts/Poppins-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/assets/site/fonts/Poppins-ExtraBold.woff2') format('woff2'),
    url('/assets/site/fonts/Poppins-ExtraBold.woff') format('woff'),
    url('/assets/site/fonts/Poppins-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/assets/site/fonts/Poppins-Medium.woff2') format('woff2'),
    url('/assets/site/fonts/Poppins-Medium.woff') format('woff'),
    url('/assets/site/fonts/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/assets/site/fonts/Poppins-ExtraLightItalic.woff2') format('woff2'),
    url('/assets/site/fonts/Poppins-ExtraLightItalic.woff') format('woff'),
    url('/assets/site/fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/assets/site/fonts/Poppins-BlackItalic.woff2') format('woff2'),
    url('/assets/site/fonts/Poppins-BlackItalic.woff') format('woff'),
    url('/assets/site/fonts/Poppins-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/assets/site/fonts/Poppins-Italic.woff2') format('woff2'),
    url('/assets/site/fonts/Poppins-Italic.woff') format('woff'),
    url('/assets/site/fonts/Poppins-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

// @font-face {
//     font-family: 'Poppins';
//     src: url('/assets/site/fonts/Poppins-Thin.woff2') format('woff2'),
//     url('/assets/site/fonts/Poppins-Thin.woff') format('woff'),
//     url('/assets/site/fonts/Poppins-Thin.ttf') format('truetype');
//     font-weight: 100;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Poppins';
//     src: url('/assets/site/fonts/Poppins-LightItalic.woff2') format('woff2'),
//     url('/assets/site/fonts/Poppins-LightItalic.woff') format('woff'),
//     url('/assets/site/fonts/Poppins-LightItalic.ttf') format('truetype');
//     font-weight: 300;
//     font-style: italic;
//     font-display: swap;
// }

@font-face {
    font-family: 'Poppins';
    src: url('/assets/site/fonts/Poppins-Black.woff2') format('woff2'),
    url('/assets/site/fonts/Poppins-Black.woff') format('woff'),
    url('/assets/site/fonts/Poppins-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/assets/site/fonts/Poppins-Bold.woff2') format('woff2'),
    url('/assets/site/fonts/Poppins-Bold.woff') format('woff'),
    url('/assets/site/fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/assets/site/fonts/Poppins-MediumItalic.woff2') format('woff2'),
    url('/assets/site/fonts/Poppins-MediumItalic.woff') format('woff'),
    url('/assets/site/fonts/Poppins-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
