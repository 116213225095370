body {
  font-family: "Poppins", sans-serif;
  background: $background-default;
  color: $font-color;
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 135%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &.is-menu-visible {
    overflow: hidden;
  }
}

hr {
  margin: 4.5rem 0;
  color: rgba(164, 174, 198, 0.2);
  background-color: currentColor;
  border: 0;
  opacity: 1;
  &:not([size]) {
    height: 1px;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: calc(1.27rem + 0.24vw);
}

@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 1.45rem;
  }
}

h1.fs-24 {
  text-align: left;
}

h2,
.h2 {
  font-size: calc(1.255rem + 0.06vw);
}

@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 1.3rem;
  }
}

h3,
.h3 {
  font-size: 1.1rem;
}

h4,
.h4 {
  font-size: 0.95rem;
}

h5,
.h5 {
  font-size: 0.9rem;
}

h6,
.h6 {
  font-size: 0.85rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.card-body p.col-10 {
  margin-bottom: 0;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.6rem;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: $primary-color;
  text-decoration: none;
}

a:hover {
  color: $primary-color-500;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.4rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}
