.helpdesk {
  position: relative;
  min-height: 2000px;
  margin: 0;
  background: #f9fafb;
}

.zammad-form {
    .btn {
      &, &:active {
        background-color: #fff000 !important;
        border-color: #fff000 !important;
        color: #000000 !important;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
      }
      &:hover, &:focus, &:focus, &:active, &:disabled, &:disabled {
        color: #ffffff !important;
        background-color: #a89e00 !important;
        border-color: #a89e00 !important;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
      }
      &.disabled, &:disabled {
        color: #000000 !important;
        background-color: #a89e00 !important;
        border-color: #a89e00 !important;
      }
    }
}
