.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.justify-left {
  justify-content: flex-start;
}
.justify-between {
  justify-content: space-between;
}
