@import url("./bootstrap-grid.min.css");
@import url("./bootstrap-reboot.min.css");
@import url("./bootstrap.min.css");
@import url("./dropdown-style.css");
@import url("./mbr-additional.css");
@import url("./popup.css");
@import url("./recaptcha.css");
@import url("./ticker-style.css");
@import url("./mobirise2.css");
@import url("./socicon.css");

// Grid
@import "components/grid";

// Settings & Tools Global variables, default mixins and functions
@import "settings/variables", "settings/colors";

// Tools: Default mixins and functions
@import "tools/breakpoints", "tools/icons";

// Generic: Ground-zero styles (Normalize.css, resets, box-sizing)
@import "generic/scaffolding", "generic/fonts", "generic/typo",
  "generic/spacers";

// Components: Designed components, chunks of UI
@import "components/card";

@import "pages/deposit.scss";
@import "pages/profile.scss";

// Trumps: Helpers and overrides
@import "trumps/third-party-game.scss", "trumps/animate.css",
  "trumps/helper.scss";

@import "main.scss";

// Register page
@import "./pages/register";

// Left sidebar
@import "./components/left-sidebar";

// Login page
@import "./pages/login";

// My account page
@import "./pages/my-account";

// Helpdesk page
@import "./pages/helpdesk";

// Terms
@import "./pages/terms";

// custom-page
@import "./pages/custom-page";

// Faq
@import "./pages/faq";

// Pagination

@import "./components/pagination";

// blocked-page
@import "./pages/blocked-page";
