.blocked-page {
  background-color: #f5811e;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  &__logo {
    margin-bottom: 32px;
    img {
      max-width: 320px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      height: auto;
      @media screen and (max-width: 768px) {
        max-width: 240px;
      }
    }
  }
  &__content {
    color: #fff;
    max-width: 920px;
    margin-left: auto;
    margin-right: auto;
    font-size: 22px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 24px;
    @media screen and (max-width: 768px) {
      margin-bottom: 12px;
    }
    &--vpn {
      max-width: 1200px;
    }
    p {
      margin-bottom: 0;
    }
    a {
      color: #fff;
      text-decoration: underline;
    }
    @media screen and (max-width: 768px) {
      font-size: 15px;
    }
  }
  &__items {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
    .item {
      flex: 0 0 24%;
      max-width: 24%;
      @media screen and (max-width: 768px) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 16px;
      }
      &-number {
        font-size: 45px;
        font-weight: bold;
        text-align: center;
        @media screen and (max-width: 768px) {
          font-size: 36px;
        }
      }
      &-title {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        @media screen and (max-width: 768px) {
          font-size: 15px;
        }
      }
      &-content {
        font-size: 18px;
        text-align: center;
        @media screen and (max-width: 768px) {
          font-size: 15px;
        }
        a {
          text-decoration: underline;
        }
      }
    }
  }
}
