section {
  background-color: #ffffff;
}

#iframe-game,
#iframe-game.pb-8 section#iframe-game {
  background-color: #0d0d0d !important;
}

body {
  font-style: normal;
  line-height: 1.5;
  font-weight: 400;
  color: #232323;
  position: relative;
}

button {
  background-color: transparent;
  border-color: transparent;
}

section,
.container,
.container-fluid {
  position: relative;
  word-wrap: break-word;
}

a.mbr-iconfont:hover {
  text-decoration: none;
}

.article .lead p,
.article .lead ul,
.article .lead ol,
.article .lead pre,
.article .lead blockquote {
  margin-bottom: 0;
}

a {
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}
a,
a:hover {
  text-decoration: none;
}

.mbr-section-title {
  font-style: normal;
  line-height: 1.3;
}

.mbr-section-subtitle {
  line-height: 1.3;
}

.mbr-text {
  font-style: normal;
  line-height: 1.3;
}

h1,
h2,
h3,
h4,
h5,
h6,
.display-1,
.display-2,
.display-4,
.display-5,
.display-7,
span,
p,
a {
  line-height: 1;
  word-break: break-word;
  word-wrap: break-word;
  font-weight: 400;
}

b,
strong {
  font-weight: 600 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition-delay: 9999s;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
}

textarea[type="hidden"] {
  display: none;
}

section {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
section .mbr-background-video,
section .mbr-background-video-preview {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.hidden {
  visibility: hidden;
}

.mbr-z-index20 {
  z-index: 20;
}

/*! Base colors */
.mbr-white {
  color: #ffffff;
}

.mbr-black {
  color: #111111;
}

.mbr-bg-white {
  background-color: #ffffff;
}

.mbr-bg-black {
  background-color: #000000;
}

/*! Text-aligns */
.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

/*! Font-weight  */
.mbr-light {
  font-weight: 300;
}

.mbr-regular {
  font-weight: 400;
}

.mbr-semibold {
  font-weight: 500;
}

.mbr-bold {
  font-weight: 700;
}

/*! Media  */
.media-content {
  flex-basis: 100%;
}

.media-container-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: start;
}
.media-container-row .media-size-item {
  width: 400px;
}

.media-container-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: stretch;
}
.media-container-column > * {
  width: 100%;
}

@media (min-width: 992px) {
  .media-container-row {
    flex-wrap: nowrap;
  }
}
figure {
  margin-bottom: 0;
  overflow: hidden;
}

figure[mbr-media-size] {
  transition: width 0.1s;
}

img,
iframe {
  display: block;
  width: 100%;
}

.card {
  background-color: transparent;
  border: none;
}

.card-box {
  width: 100%;
}

.card-img {
  text-align: center;
  flex-shrink: 0;
  -webkit-flex-shrink: 0;
}

.media {
  max-width: 100%;
  margin: 0 auto;
}

.mbr-figure {
  align-self: center;
}

.media-container > div {
  max-width: 100%;
}

.mbr-figure img,
.card-img img {
  width: 100%;
}

@media (max-width: 991px) {
  .media-size-item {
    width: auto !important;
  }

  .media {
    width: auto;
  }

  .mbr-figure {
    width: 100% !important;
  }
}
/*! Buttons */
.mbr-section-btn {
  margin-left: -0.6rem;
  margin-right: -0.6rem;
  font-size: 0;
}

.btn {
  font-weight: 600;
  border-width: 1px;
  font-style: normal;
  margin: 0.6rem 0.6rem;
  white-space: normal;
  transition: all 0.2s ease-in-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  word-break: break-word;
}

.btn-sm {
  font-weight: 600;
  letter-spacing: 0px;
  transition: all 0.3s ease-in-out;
}

.btn-md {
  font-weight: 600;
  letter-spacing: 0px;
  transition: all 0.3s ease-in-out;
}

.btn-lg {
  font-weight: 600;
  letter-spacing: 0px;
  transition: all 0.3s ease-in-out;
}

.btn-form {
  margin: 0;
}
.btn-form:hover {
  cursor: pointer;
}

nav .mbr-section-btn {
  margin-left: 0rem;
  margin-right: 0rem;
}

/*! Btn icon margin */
.btn .mbr-iconfont,
.btn.btn-sm .mbr-iconfont {
  order: 1;
  cursor: pointer;
  margin-left: 0.5rem;
  vertical-align: sub;
}

.btn.btn-md .mbr-iconfont,
.btn.btn-md .mbr-iconfont {
  margin-left: 0.8rem;
}

.mbr-regular {
  font-weight: 400;
}

.mbr-semibold {
  font-weight: 500;
}

.mbr-bold {
  font-weight: 700;
}

[type="submit"] {
  -webkit-appearance: none;
}

/*! Full-screen */
.mbr-fullscreen .mbr-overlay {
  min-height: 100vh;
}

.mbr-fullscreen {
  display: flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  align-items: center;
  min-height: 100vh;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

/*! Map */
.map {
  height: 25rem;
  position: relative;
}
.map iframe {
  width: 100%;
  height: 100%;
}

/*! Scroll to top arrow */
.mbr-arrow-up {
  bottom: 25px;
  right: 90px;
  position: fixed;
  text-align: right;
  z-index: 5000;
  color: #ffffff;
  font-size: 22px;
}

.mbr-arrow-up a {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  height: 60px;
  width: 60px;
  border: 2px solid #fff;
  outline-style: none !important;
  position: relative;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  text-align: center;
}
.mbr-arrow-up a:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.mbr-arrow-up a i {
  line-height: 60px;
}

.mbr-arrow-up-icon {
  display: block;
  color: #fff;
}

.mbr-arrow-up-icon::before {
  content: "›";
  display: inline-block;
  font-family: serif;
  font-size: 22px;
  line-height: 1;
  font-style: normal;
  position: relative;
  top: 6px;
  left: -4px;
  transform: rotate(-90deg);
}

/*! Arrow Down */
.mbr-arrow {
  position: absolute;
  bottom: 45px;
  left: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  background-color: rgba(80, 80, 80, 0.5);
  border-radius: 50%;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .mbr-arrow {
    display: none;
  }
}
.mbr-arrow > a {
  display: inline-block;
  text-decoration: none;
  outline-style: none;
  -webkit-animation: arrowdown 1.7s ease-in-out infinite;
  animation: arrowdown 1.7s ease-in-out infinite;
  color: #ffffff;
}
.mbr-arrow > a > i {
  position: absolute;
  top: -2px;
  left: 15px;
  font-size: 2rem;
}

#scrollToTop a i::before {
  content: "";
  position: absolute;
  display: block;
  border-bottom: 2.5px solid #fff;
  border-left: 2.5px solid #fff;
  width: 27.8%;
  height: 27.8%;
  left: 50%;
  top: 51%;
  transform: translateY(-30%) translateX(-50%) rotate(135deg);
}

@keyframes arrowdown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-webkit-keyframes arrowdown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}
@media (max-width: 500px) {
  .mbr-arrow-up {
    left: 0;
    right: 0;
    text-align: center;
  }
}
/*Gradients animation*/
@keyframes gradient-animation {
  from {
    background-position: 0% 100%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  to {
    background-position: 100% 0%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
}
@-webkit-keyframes gradient-animation {
  from {
    background-position: 0% 100%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  to {
    background-position: 100% 0%;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
}
.bg-gradient {
  background-size: 200% 200%;
  animation: gradient-animation 5s infinite alternate;
  -webkit-animation: gradient-animation 5s infinite alternate;
}

.menu .navbar-brand {
  display: -webkit-flex;
}
.menu .navbar-brand span {
  display: flex;
  display: -webkit-flex;
}
.menu .navbar-brand .navbar-caption-wrap {
  display: -webkit-flex;
}
.menu .navbar-brand .navbar-logo img {
  display: -webkit-flex;
  width: auto;
}
@media (min-width: 768px) and (max-width: 991px) {
  .menu .navbar-toggleable-sm .navbar-nav {
    display: -ms-flexbox;
  }
}
@media (max-width: 991px) {
  .menu .navbar-collapse {
    max-height: 93.5vh;
  }
  .menu .navbar-collapse.show {
    overflow: auto;
  }
}
@media (min-width: 992px) {
  .menu .navbar-nav.nav-dropdown {
    display: -webkit-flex;
  }
  .menu .navbar-toggleable-sm .navbar-collapse {
    display: -webkit-flex !important;
  }
  .menu .collapsed .navbar-collapse {
    max-height: 93.5vh;
  }
  .menu .collapsed .navbar-collapse.show {
    overflow: auto;
  }
}
@media (max-width: 767px) {
  .menu .navbar-collapse {
    max-height: 80vh;
  }
}

.nav-link .mbr-iconfont {
  margin-right: 0.5rem;
}

.navbar {
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -webkit-align-items: center;
  -webkit-justify-content: space-between;
}

.navbar-collapse {
  -webkit-flex-basis: 100%;
  -webkit-flex-grow: 1;
  -webkit-align-items: center;
}

.nav-dropdown .link {
  padding: 0.667em 1.667em !important;
  margin: 0 !important;
}

.nav {
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
}

.row {
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
}

.justify-content-center {
  -webkit-justify-content: center;
}

.form-inline {
  display: -webkit-flex;
}

.card-wrapper {
  -webkit-flex: 1;
}

.carousel-control {
  z-index: 10;
  display: -webkit-flex;
}

.carousel-controls {
  display: -webkit-flex;
}

.media {
  display: -webkit-flex;
}

.form-group:focus {
  outline: none;
}

.jq-selectbox__select {
  padding: 7px 0;
  position: relative;
}

.jq-selectbox__dropdown {
  overflow: hidden;
  border-radius: 10px;
  position: absolute;
  top: 100%;
  left: 0 !important;
  width: 100% !important;
}

.jq-selectbox__trigger-arrow {
  right: 0;
  transform: translateY(-50%);
}

.jq-selectbox li {
  padding: 1.07em 0.5em;
}

input[type="range"] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.modal-dialog,
.modal-content {
  height: 100%;
}

.modal-dialog .carousel-inner {
  height: calc(100vh - 1.75rem);
}
@media (max-width: 575px) {
  .modal-dialog .carousel-inner {
    height: calc(100vh - 1rem);
  }
}

.carousel-item {
  text-align: center;
}

.carousel-item img {
  margin: auto;
}

.navbar-toggler {
  align-self: flex-start;
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
  box-shadow: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

.navbar-toggler-left {
  position: absolute;
  left: 1rem;
}

.navbar-toggler-right {
  position: absolute;
  right: 1rem;
}

.card-img {
  width: auto;
}

.menu .navbar.collapsed:not(.beta-menu) {
  flex-direction: column;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: flex;
}

.note-air-layout .dropup .dropdown-menu,
.note-air-layout .navbar-fixed-bottom .dropdown .dropdown-menu {
  bottom: initial !important;
}

html,
body {
  height: auto;
  min-height: 100vh;
}

.dropup .dropdown-toggle::after {
  display: none;
}

.form-asterisk {
  font-family: initial;
  position: absolute;
  top: -2px;
  font-weight: normal;
}

.form-control-label {
  position: relative;
  cursor: pointer;
  margin-bottom: 0.357em;
  padding: 0;
}

.alert {
  color: #ffffff;
  border-radius: 0;
  border: 0;
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 1.875rem;
  padding: 1.25rem;
  position: relative;
  text-align: center;
}
.alert.alert-form::after {
  background-color: inherit;
  bottom: -7px;
  content: "";
  display: block;
  height: 14px;
  left: 50%;
  margin-left: -7px;
  position: absolute;
  transform: rotate(45deg);
  width: 14px;
}

.form-control {
  background-color: #ffffff;
  background-clip: border-box;
  color: #232323;
  line-height: 1rem !important;
  height: auto;
  padding: 0.6rem 1.2rem;
  transition: border-color 0.25s ease 0s;
  border: 1px solid transparent !important;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.07) 0px 1px 3px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;
}
.form-active .form-control:invalid {
  border-color: red;
}

form .row {
  margin-left: -0.6rem;
  margin-right: -0.6rem;
}
form .row [class*="col"] {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

form .mbr-section-btn {
  margin: 0;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

form .btn {
  display: flex;
  padding: 0.6rem 1.2rem;
  margin: 0;
}

form .form-check-input {
  margin-top: 0.5;
}

textarea.form-control {
  line-height: 1.5rem !important;
}

.form-group {
  margin-bottom: 1.2rem;
}

.form-control,
form .btn {
  min-height: 48px;
}

.gdpr-block label span.textGDPR input[name="gdpr"] {
  top: 7px;
}

.form-control:focus {
  box-shadow: none;
}

:focus {
  outline: none;
}

.mbr-overlay {
  background-color: #000;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}

blockquote {
  font-style: italic;
  padding: 3rem;
  font-size: 1.09rem;
  position: relative;
  border-left: 3px solid;
}

ul,
ol,
pre,
blockquote {
  margin-bottom: 2.3125rem;
}

.mt-4 {
  margin-top: 2rem !important;
}

.mb-4 {
  margin-bottom: 2rem !important;
}

@media (min-width: 992px) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .row {
    margin-left: -16px;
    margin-right: -16px;
  }
  .row > [class*="col"] {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 768px) {
  .container-fluid {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .mbr-container {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (max-width: 767px) {
  .mbr-container {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.card-wrapper,
.item-wrapper {
  overflow: hidden;
}

.app-video-wrapper > img {
  opacity: 1;
}

.item {
  position: relative;
}

.dropdown-menu .dropdown-menu {
  left: 100%;
}

.dropdown-item + .dropdown-menu {
  display: none;
}

.dropdown-item:hover + .dropdown-menu,
.dropdown-menu:hover {
  display: block;
}
body {
  font-family: "Montserrat", sans-serif;
}
.display-1 {
  font-family: "Montserrat", sans-serif;
  font-size: 3rem;
  line-height: 0.9;
  letter-spacing: 0.0625rem;
}
.display-1 > .mbr-iconfont {
  font-size: 3.75rem;
}
.display-2 {
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  line-height: 0.8;
}
.display-2 > .mbr-iconfont {
  font-size: 2.5rem;
}
.display-4 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.7rem;
  line-height: 1;
}
.display-4 > .mbr-iconfont {
  font-size: 2.125rem;
}
.display-5 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.8rem;
  line-height: 1.1;
}
.display-5 > .mbr-iconfont {
  font-size: 2.25rem;
}
.display-7 {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}
.display-7 > .mbr-iconfont {
  font-size: 1.25rem;
}
.display-8 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  line-height: 1.6;
  font-weight: bold;
}
.display-8 > .mbr-iconfont {
  font-size: 1.25rem;
}
/* ---- Fluid typography for mobile devices ---- */
/* 1.4 - font scale ratio ( bootstrap == 1.42857 ) */
/* 100vw - current viewport width */
/* (48 - 20)  48 == 48rem == 768px, 20 == 20rem == 320px(minimal supported viewport) */
/* 0.65 - min scale variable, may vary */
@media (max-width: 992px) {
  .display-1 {
    font-size: 2.4rem;
  }
}
@media (max-width: 768px) {
  .display-1 {
    font-size: 2.1rem;
    font-size: calc(1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc(
      0.9 * (1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20)))
    );
  }
  .display-2 {
    font-size: 1.6rem;
    font-size: calc(1.35rem + (2 - 1.35) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc(
      0.8 * (1.35rem + (2 - 1.35) * ((100vw - 20rem) / (48 - 20)))
    );
  }
  .display-4 {
    font-size: 1.36rem;
    font-size: calc(1.245rem + (1.7 - 1.245) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc(
      1 * (1.245rem + (1.7 - 1.245) * ((100vw - 20rem) / (48 - 20)))
    );
  }
  .display-5 {
    font-size: 1.44rem;
    font-size: calc(1.28rem + (1.8 - 1.28) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc(
      1.1 * (1.28rem + (1.8 - 1.28) * ((100vw - 20rem) / (48 - 20)))
    );
  }
  .display-7 {
    font-size: 0.8rem;
    font-size: calc(1rem + (1 - 1) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc(1.3 * (1rem + (1 - 1) * ((100vw - 20rem) / (48 - 20))));
  }
}
/* Buttons */
.btn {
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
}
.btn-sm {
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
}
.btn-md {
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
}
.btn-lg {
  padding: 1rem 2.6rem;
  border-radius: 4px;
}
.bg-primary {
  background-color: #fff000 !important;
}
.bg-success {
  background-color: #a38482 !important;
}
.bg-info {
  background-color: #d9915c !important;
}
.bg-warning {
  background-color: #ffc092 !important;
}
.bg-danger {
  background-color: #000000 !important;
}
.btn-primary,
.btn-primary:active {
  background-color: #fff000 !important;
  border-color: #fff000 !important;
  color: #000000 !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary.active {
  color: #ffffff !important;
  background-color: #a89e00 !important;
  border-color: #a89e00 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #000000 !important;
  background-color: #a89e00 !important;
  border-color: #a89e00 !important;
}
.btn-secondary,
.btn-secondary:active {
  background-color: #55ec25 !important;
  border-color: #55ec25 !important;
  color: #051101 !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary.active {
  color: #ffffff !important;
  background-color: #35ab0f !important;
  border-color: #35ab0f !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #051101 !important;
  background-color: #35ab0f !important;
  border-color: #35ab0f !important;
}
.btn-info,
.btn-info:active {
  background-color: #d9915c !important;
  border-color: #d9915c !important;
  color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-info:hover,
.btn-info:focus,
.btn-info.focus,
.btn-info.active {
  color: #ffffff !important;
  background-color: #b4652a !important;
  border-color: #b4652a !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #ffffff !important;
  background-color: #b4652a !important;
  border-color: #b4652a !important;
}
.btn-success,
.btn-success:active {
  background-color: #a38482 !important;
  border-color: #a38482 !important;
  color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-success:hover,
.btn-success:focus,
.btn-success.focus,
.btn-success.active {
  color: #ffffff !important;
  background-color: #775957 !important;
  border-color: #775957 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #ffffff !important;
  background-color: #775957 !important;
  border-color: #775957 !important;
}
.btn-warning,
.btn-warning:active {
  background-color: #ffc092 !important;
  border-color: #ffc092 !important;
  color: #923e00 !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus,
.btn-warning.active {
  color: #ffffff !important;
  background-color: #ff8e3b !important;
  border-color: #ff8e3b !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #923e00 !important;
  background-color: #ff8e3b !important;
  border-color: #ff8e3b !important;
}
.btn-danger,
.btn-danger:active {
  background-color: #000000 !important;
  border-color: #000000 !important;
  color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus,
.btn-danger.active {
  color: #ffffff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #ffffff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
}
.btn-white,
.btn-white:active {
  background-color: #fafafa !important;
  border-color: #fafafa !important;
  color: #7a7a7a !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-white:hover,
.btn-white:focus,
.btn-white.focus,
.btn-white.active {
  color: #4f4f4f !important;
  background-color: #cfcfcf !important;
  border-color: #cfcfcf !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-white.disabled,
.btn-white:disabled {
  color: #7a7a7a !important;
  background-color: #cfcfcf !important;
  border-color: #cfcfcf !important;
}
.btn-black,
.btn-black:active {
  background-color: #232323 !important;
  border-color: #232323 !important;
  color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-black:hover,
.btn-black:focus,
.btn-black.focus,
.btn-black.active {
  color: #ffffff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-black.disabled,
.btn-black:disabled {
  color: #ffffff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
}
.btn-primary-outline,
.btn-primary-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #fff000;
}
.btn-primary-outline:hover,
.btn-primary-outline:focus,
.btn-primary-outline.focus,
.btn-primary-outline.active {
  color: #a89e00 !important;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.btn-primary-outline.disabled,
.btn-primary-outline:disabled {
  color: #000000 !important;
  background-color: #fff000 !important;
  border-color: #fff000 !important;
}
.btn-secondary-outline,
.btn-secondary-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #55ec25;
}
.btn-secondary-outline:hover,
.btn-secondary-outline:focus,
.btn-secondary-outline.focus,
.btn-secondary-outline.active {
  color: #35ab0f !important;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.btn-secondary-outline.disabled,
.btn-secondary-outline:disabled {
  color: #051101 !important;
  background-color: #55ec25 !important;
  border-color: #55ec25 !important;
}
.btn-info-outline,
.btn-info-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #d9915c;
}
.btn-info-outline:hover,
.btn-info-outline:focus,
.btn-info-outline.focus,
.btn-info-outline.active {
  color: #b4652a !important;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.btn-info-outline.disabled,
.btn-info-outline:disabled {
  color: #ffffff !important;
  background-color: #d9915c !important;
  border-color: #d9915c !important;
}
.btn-success-outline,
.btn-success-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #a38482;
}
.btn-success-outline:hover,
.btn-success-outline:focus,
.btn-success-outline.focus,
.btn-success-outline.active {
  color: #775957 !important;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.btn-success-outline.disabled,
.btn-success-outline:disabled {
  color: #ffffff !important;
  background-color: #a38482 !important;
  border-color: #a38482 !important;
}
.btn-warning-outline,
.btn-warning-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #ffc092;
}
.btn-warning-outline:hover,
.btn-warning-outline:focus,
.btn-warning-outline.focus,
.btn-warning-outline.active {
  color: #ff8e3b !important;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.btn-warning-outline.disabled,
.btn-warning-outline:disabled {
  color: #923e00 !important;
  background-color: #ffc092 !important;
  border-color: #ffc092 !important;
}
.btn-danger-outline,
.btn-danger-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #000000;
}
.btn-danger-outline:hover,
.btn-danger-outline:focus,
.btn-danger-outline.focus,
.btn-danger-outline.active {
  color: #000000 !important;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.btn-danger-outline.disabled,
.btn-danger-outline:disabled {
  color: #ffffff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
}
.btn-black-outline,
.btn-black-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #232323;
}
.btn-black-outline:hover,
.btn-black-outline:focus,
.btn-black-outline.focus,
.btn-black-outline.active {
  color: #000000 !important;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.btn-black-outline.disabled,
.btn-black-outline:disabled {
  color: #ffffff !important;
  background-color: #232323 !important;
  border-color: #232323 !important;
}
.btn-white-outline,
.btn-white-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #fafafa;
}
.btn-white-outline:hover,
.btn-white-outline:focus,
.btn-white-outline.focus,
.btn-white-outline.active {
  color: #cfcfcf !important;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.btn-white-outline.disabled,
.btn-white-outline:disabled {
  color: #7a7a7a !important;
  background-color: #fafafa !important;
  border-color: #fafafa !important;
}
.text-primary {
  color: #fff000 !important;
}
.text-secondary {
  color: #55ec25 !important;
}
.text-success {
  color: #a38482 !important;
}
.text-info {
  color: #d9915c !important;
}
.text-warning {
  color: #ffc092 !important;
}
.text-danger {
  color: #000000 !important;
}
.text-white {
  color: #fafafa !important;
}
.text-black {
  color: #232323 !important;
}
a.text-primary:hover,
a.text-primary:focus,
a.text-primary.active {
  color: #999000 !important;
}
a.text-secondary:hover,
a.text-secondary:focus,
a.text-secondary.active {
  color: #309d0e !important;
}
a.text-success:hover,
a.text-success:focus,
a.text-success.active {
  color: #6e5351 !important;
}
a.text-info:hover,
a.text-info:focus,
a.text-info.active {
  color: #a85e27 !important;
}
a.text-warning:hover,
a.text-warning:focus,
a.text-warning.active {
  color: #ff852c !important;
}
a.text-danger:hover,
a.text-danger:focus,
a.text-danger.active {
  color: #000000 !important;
}
a.text-white:hover,
a.text-white:focus,
a.text-white.active {
  color: #c7c7c7 !important;
}
a.text-black:hover,
a.text-black:focus,
a.text-black.active {
  color: #000000 !important;
}
a[class*="text-"]:not(.nav-link):not(.dropdown-item):not([role]):not(
    .navbar-caption
  ) {
  position: relative;
  background-image: transparent;
  background-size: 10000px 2px;
  background-repeat: no-repeat;
  background-position: 0px 1.2em;
  background-position: -10000px 1.2em;
}
a[class*="text-"]:not(.nav-link):not(.dropdown-item):not([role]):not(
    .navbar-caption
  ):hover {
  transition: background-position 2s ease-in-out;
  background-image: linear-gradient(currentColor 50%, currentColor 50%);
  background-position: 0px 1.2em;
}
.nav-tabs .nav-link.active {
  color: #fff000;
}
.nav-tabs .nav-link:not(.active) {
  color: #232323;
}
.alert-success {
  background-color: #70c770;
}
.alert-info {
  background-color: #d9915c;
}
.alert-warning {
  background-color: #ffc092;
}
.alert-danger {
  background-color: #000000;
}
.mbr-gallery-filter li.active .btn {
  background-color: #fff000;
  border-color: #fff000;
  color: #191800;
}
.mbr-gallery-filter li.active .btn:focus {
  box-shadow: none;
}
a.text-yellow,
a.text-yellow:hover {
  color: #fff000;
}
.mbr-plan-header.bg-primary .mbr-plan-subtitle,
.mbr-plan-header.bg-primary .mbr-plan-price-desc {
  color: #fffccc;
}
.mbr-plan-header.bg-success .mbr-plan-subtitle,
.mbr-plan-header.bg-success .mbr-plan-price-desc {
  color: #d9cccb;
}
.mbr-plan-header.bg-info .mbr-plan-subtitle,
.mbr-plan-header.bg-info .mbr-plan-price-desc {
  color: #ffffff;
}
.mbr-plan-header.bg-warning .mbr-plan-subtitle,
.mbr-plan-header.bg-warning .mbr-plan-price-desc {
  color: #ffffff;
}
.mbr-plan-header.bg-danger .mbr-plan-subtitle,
.mbr-plan-header.bg-danger .mbr-plan-price-desc {
  color: #b3b3b3;
}
/* Scroll to top button*/
.scrollToTop_wraper {
  display: none;
}
.form-control {
  font-family: "Montserrat", sans-serif;
  font-size: 1.7rem;
  line-height: 1;
  font-weight: 400;
  color: #000000;
}
.form-control > .mbr-iconfont {
  font-size: 2.125rem;
}
.form-control:hover,
.form-control:focus {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.07) 0px 1px 3px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;
  border-color: #fff000 !important;
}
.form-control:-webkit-input-placeholder {
  font-family: "Montserrat", sans-serif;
  font-size: 1.7rem;
  line-height: 1;
  font-weight: 400;
}
.form-control:-webkit-input-placeholder > .mbr-iconfont {
  font-size: 2.125rem;
}
blockquote {
  border-color: #fff000;
}
/* Forms */
.jq-selectbox li:hover,
.jq-selectbox li.selected {
  background-color: #fff000;
  color: #000000;
}
.jq-number__spin {
  transition: 0.25s ease;
}
.jq-number__spin:hover {
  border-color: #fff000;
}
.jq-selectbox .jq-selectbox__trigger-arrow,
.jq-number__spin.minus:after,
.jq-number__spin.plus:after {
  transition: 0.4s;
  border-top-color: #353535;
  border-bottom-color: #353535;
}
.jq-selectbox:hover .jq-selectbox__trigger-arrow,
.jq-number__spin.minus:hover:after,
.jq-number__spin.plus:hover:after {
  border-top-color: #fff000;
  border-bottom-color: #fff000;
}
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
.xdsoft_datetimepicker
  .xdsoft_timepicker
  .xdsoft_time_box
  > div
  > div.xdsoft_current {
  color: #000000 !important;
  background-color: #fff000 !important;
  box-shadow: none !important;
}
.xdsoft_datetimepicker .xdsoft_calendar td:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
  color: #000000 !important;
  background: #55ec25 !important;
  box-shadow: none !important;
}
.lazy-bg {
  background-image: none !important;
}
.lazy-placeholder:not(section),
.lazy-none {
  display: block;
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  height: auto;
}
iframe.lazy-placeholder,
.lazy-placeholder:after {
  content: "";
  position: absolute;
  width: 200px;
  height: 200px;
  background: transparent no-repeat center;
  background-size: contain;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='32' height='32' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg' stroke='%23fff000' %3e%3cg fill='none' fill-rule='evenodd'%3e%3cg transform='translate(16 16)' stroke-width='2'%3e%3ccircle stroke-opacity='.5' cx='16' cy='16' r='16'/%3e%3cpath d='M32 16c0-9.94-8.06-16-16-16'%3e%3canimateTransform attributeName='transform' type='rotate' from='0 16 16' to='360 16 16' dur='1s' repeatCount='indefinite'/%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
}
section.lazy-placeholder:after {
  opacity: 0.5;
}
body {
  overflow-x: hidden;
}
a {
  transition: color 0.6s;
}
.container {
  max-width: 1056px;
}
.btn {
  border-radius: 100vw;
  padding: 0.75rem 1.5rem;
}
.cid-ubvvRXbv8W {
  padding-top: 0rem;
  padding-bottom: 0rem;
  background: #411541;
}
.cid-ubvvRXbv8W .mbr-fallback-image.disabled {
  display: none;
}
.cid-ubvvRXbv8W .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-ubvvRXbv8W .container,
.cid-ubvvRXbv8W .container-fluid {
  padding: 0 0px;
}
@media (max-width: 992px) {
  .cid-ubvvRXbv8W .container,
  .cid-ubvvRXbv8W .container-fluid {
    padding: 0 0px;
  }
}
.cid-ubvvRXbv8W .embla__slide {
  display: flex;
  justify-content: center;
  position: relative;
  min-width: 100%;
  max-width: 100%;
}
@media (max-width: 768px) {
  .cid-ubvvRXbv8W .embla__slide {
    min-width: 100%;
    max-width: 100%;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
.cid-ubvvRXbv8W .embla__slide .card-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 72px;
}
@media (max-width: 992px) {
  .cid-ubvvRXbv8W .embla__slide .card-wrap {
    grid-template-columns: 1fr;
  }
}
.cid-ubvvRXbv8W .embla__slide .card-wrap .item-wrapper {
  display: flex;
}
@media (max-width: 992px) {
  .cid-ubvvRXbv8W .embla__slide .card-wrap .item-wrapper {
    flex-direction: column;
  }
}
.cid-ubvvRXbv8W .embla__slide .card-wrap .item-wrapper .desc-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}
@media (max-width: 992px) {
  .cid-ubvvRXbv8W .embla__slide .card-wrap .item-wrapper .desc-wrap {
    position: static;
    margin-bottom: 20px;
  }
}
.cid-ubvvRXbv8W .embla__slide .card-wrap .item-wrapper .desc-wrap .mbr-desc {
  display: inline-flex;
  padding: 8px 10px;
  background-color: #8a9198;
  margin-bottom: 10px;
}
.cid-ubvvRXbv8W .embla__slide .card-wrap .item-wrapper .image-wrap {
  margin: 0 0 0 auto;
}
@media (max-width: 992px) {
  .cid-ubvvRXbv8W .embla__slide .card-wrap .item-wrapper .image-wrap {
    margin: 0 auto 0px;
  }
}
.cid-ubvvRXbv8W .embla__slide .card-wrap .item-wrapper .image-wrap img {
  height: 324px;
  border-radius: 0%;
  object-fit: cover;
}
@media (max-width: 1200px) {
  .cid-ubvvRXbv8W .embla__slide .card-wrap .item-wrapper .image-wrap img {
    height: 324px;
  }
}
@media (max-width: 768px) {
  .cid-ubvvRXbv8W .embla__slide .card-wrap .item-wrapper .image-wrap img {
    padding-top: 10px;
    height: 324px;
  }
}
.cid-ubvvRXbv8W .embla__slide .card-wrap .content-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cid-ubvvRXbv8W .embla__slide .card-wrap .content-wrap .mbr-section-title {
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .cid-ubvvRXbv8W .embla__slide .card-wrap .content-wrap .mbr-section-title {
    padding-top: 20px;
    margin-bottom: 6px;
  }
}
.cid-ubvvRXbv8W .embla__slide .card-wrap .content-wrap .mbr-text {
  margin-bottom: 0;
}
.cid-ubvvRXbv8W .embla__button--next,
.cid-ubvvRXbv8W .embla__button--prev {
  display: flex;
}
.cid-ubvvRXbv8W .embla__button {
  top: 0;
  width: 25px;
  height: 25px;
  margin-top: -1.5rem;
  font-size: 25px;
  background-color: transparent;
  color: #fff000;
  border: none;
  border-radius: 50%;
  transition: all 0.3s;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.cid-ubvvRXbv8W .embla__button:disabled {
  cursor: default;
  display: none;
}
.cid-ubvvRXbv8W .embla__button:hover {
  background: transparent;
  color: #fff000;
  opacity: 0.5;
}
.cid-ubvvRXbv8W .embla__button.embla__button--prev {
  left: auto;
  right: 3rem;
}
.cid-ubvvRXbv8W .embla__button.embla__button--next {
  right: 0;
}
@media (max-width: 767px) {
  .cid-ubvvRXbv8W .embla__button {
    top: 0;
  }
}
.cid-ubvvRXbv8W .embla {
  position: relative;
  width: 100%;
}
.cid-ubvvRXbv8W .embla__viewport {
  overflow: hidden;
  width: 100%;
}
.cid-ubvvRXbv8W .embla__viewport.is-draggable {
  cursor: grab;
}
.cid-ubvvRXbv8W .embla__viewport.is-dragging {
  cursor: grabbing;
}
.cid-ubvvRXbv8W .embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.cid-ubvvRXbv8W .mbr-desc {
  color: #ffffff;
}
.cid-ubvvRXbv8W .mbr-section-title {
  color: #ffffff;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
@media (max-width: 768px) {
  .cid-ubvvRXbv8W .mbr-section-title {
    line-height: 22px;
    font-size: 22px;
  }
}
.cid-ubvvRXbv8W .mbr-text {
  color: #3d4045;
  text-align: center;
}
.cid-sRAtISOGzm {
  z-index: 1000;
  width: 100%;
  position: relative;
  min-height: 60px;
}
@media (min-width: 992px) {
  .cid-sRAtISOGzm {
    min-height: 105px;
  }
}
.cid-sRAtISOGzm nav.navbar {
  position: fixed;
}
.cid-sRAtISOGzm .container {
  max-width: 1312px;
  display: flex;
  margin: auto;
}
@media (min-width: 992px) {
  .cid-sRAtISOGzm .container {
    flex-wrap: nowrap;
  }
}
.cid-sRAtISOGzm .container-custom {
  position: relative;
  width: 100%;
  padding: 10px 0;
}
.cid-sRAtISOGzm .dropdown-item:before {
  font-family: Moririse2 !important;
  content: "\e966";
  display: inline-block;
  width: 0;
  position: absolute;
  left: 1rem;
  top: 0.5rem;
  margin-right: 0.5rem;
  line-height: 1;
  font-size: inherit;
  vertical-align: middle;
  text-align: center;
  overflow: hidden;
  transform: scale(0, 1);
  transition: all 0.25s ease-in-out;
}
.cid-sRAtISOGzm .dropdown-menu {
  padding: 0;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}
.cid-sRAtISOGzm .dropdown-item {
  border-bottom: 1px solid #e6e6e6;
}
.cid-sRAtISOGzm .dropdown-item:hover,
.cid-sRAtISOGzm .dropdown-item:focus {
  background: #fff000 !important;
  color: white !important;
}
.cid-sRAtISOGzm .dropdown-item:hover span {
  color: white;
}
.cid-sRAtISOGzm .dropdown-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.cid-sRAtISOGzm .dropdown-item:last-child {
  border-bottom: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.cid-sRAtISOGzm .nav-dropdown .link {
  padding: 0.75em 1em !important;
}
.cid-sRAtISOGzm .nav-dropdown .link.dropdown-toggle::after {
  margin-left: 0.5rem;
  margin-top: 0.2rem;
}
.cid-sRAtISOGzm .nav-link {
  position: relative;
}
.cid-sRAtISOGzm .iconfont-wrapper {
  color: #002549 !important;
  font-size: 1.5rem;
  padding-right: 0.5rem;
}
.cid-sRAtISOGzm .dropdown-menu,
.cid-sRAtISOGzm .navbar.opened {
  background: #f5811e !important;
}
.cid-sRAtISOGzm .nav-item:focus,
.cid-sRAtISOGzm .nav-link:focus {
  outline: none;
}
.cid-sRAtISOGzm .dropdown .dropdown-menu .dropdown-item {
  width: auto;
  transition: all 0.25s ease-in-out;
}
.cid-sRAtISOGzm .dropdown .dropdown-menu .dropdown-item::after {
  right: 0.5rem;
}
.cid-sRAtISOGzm .dropdown .dropdown-menu .dropdown-item .mbr-iconfont {
  margin-right: 0.5rem;
  vertical-align: sub;
}
.cid-sRAtISOGzm .dropdown .dropdown-menu .dropdown-item .mbr-iconfont:before {
  display: inline-block;
  transform: scale(1, 1);
  transition: all 0.25s ease-in-out;
}
.cid-sRAtISOGzm .collapsed .dropdown-menu .dropdown-item:before {
  display: none;
}
.cid-sRAtISOGzm .collapsed .dropdown .dropdown-menu .dropdown-item {
  padding: 0.235em 1.5em 0.235em 1.5em !important;
  transition: none;
  margin: 0 !important;
}
.cid-sRAtISOGzm .navbar {
  min-height: 60px;
  transition: all 0.3s;
  border-bottom: 1px solid transparent;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  background: #f5811e;
}
@media (min-width: 992px) {
  .cid-sRAtISOGzm .navbar {
    min-height: 105px;
  }
}
.cid-sRAtISOGzm .navbar.opened {
  transition: all 0.3s;
}
.cid-sRAtISOGzm .navbar .dropdown-item {
  padding: 0.5rem 1.8rem;
}
.cid-sRAtISOGzm .navbar .navbar-logo img {
  height: 6rem !important;
}
.cid-sRAtISOGzm .navbar .navbar-collapse {
  justify-content: flex-end;
  z-index: 1;
}
@media (min-width: 992px) {
  .cid-sRAtISOGzm .navbar .navbar-collapse {
    width: 100%;
    justify-content: space-between;
    z-index: 5;
  }
}
@media (min-width: 992px) {
  .cid-sRAtISOGzm .navbar .navbar-nav-container {
    width: 40%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
}
.cid-sRAtISOGzm .navbar.collapsed {
  justify-content: center;
}
.cid-sRAtISOGzm .navbar.collapsed .nav-item .nav-link::before {
  display: none;
}
.cid-sRAtISOGzm .navbar.collapsed.opened .dropdown-menu {
  top: 0;
}
@media (min-width: 992px) {
  .cid-sRAtISOGzm .navbar.collapsed.opened:not(.navbar-short) .navbar-collapse {
    max-height: calc(98.5vh - 3rem);
  }
}
.cid-sRAtISOGzm .navbar.collapsed .dropdown-menu .dropdown-submenu {
  left: 0 !important;
}
.cid-sRAtISOGzm .navbar.collapsed .dropdown-menu .dropdown-item:after {
  right: auto;
}
.cid-sRAtISOGzm
  .navbar.collapsed
  .dropdown-menu
  .dropdown-toggle[data-toggle="dropdown-submenu"]:after {
  margin-left: 0.5rem;
  margin-top: 0.2rem;
  border-top: 0.35em solid;
  border-right: 0.35em solid transparent;
  border-left: 0.35em solid transparent;
  border-bottom: 0;
  top: 41%;
}
.cid-sRAtISOGzm .navbar.collapsed ul.navbar-nav li {
  margin: auto;
}
.cid-sRAtISOGzm .navbar.collapsed .dropdown-menu .dropdown-item {
  padding: 0.25rem 1.5rem;
  text-align: center;
}
.cid-sRAtISOGzm .navbar.collapsed .icons-menu {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
@media (max-width: 991px) {
  .cid-sRAtISOGzm .navbar .nav-item .nav-link::before {
    display: none;
  }
  .cid-sRAtISOGzm .navbar.opened .dropdown-menu {
    top: 0;
  }
  .cid-sRAtISOGzm .navbar .dropdown-menu .dropdown-submenu {
    left: 0 !important;
  }
  .cid-sRAtISOGzm .navbar .dropdown-menu .dropdown-item:after {
    right: auto;
  }
  .cid-sRAtISOGzm
    .navbar
    .dropdown-menu
    .dropdown-toggle[data-toggle="dropdown-submenu"]:after {
    margin-left: 0.5rem;
    margin-top: 0.2rem;
    border-top: 0.35em solid;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0;
    top: 40%;
  }
  .cid-sRAtISOGzm .navbar .navbar-logo img {
    height: 4rem !important;
  }
  .cid-sRAtISOGzm .navbar ul.navbar-nav li {
    margin: auto;
  }
  .cid-sRAtISOGzm .navbar .dropdown-menu .dropdown-item {
    padding: 0.25rem 1.5rem !important;
    text-align: center;
  }
  .cid-sRAtISOGzm .navbar .navbar-brand {
    flex-shrink: initial;
    flex-basis: auto;
    word-break: break-word;
    padding-right: 2rem;
  }
  .cid-sRAtISOGzm .navbar .navbar-toggler {
    flex-basis: auto;
  }
  .cid-sRAtISOGzm .navbar .icons-menu {
    padding-left: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
.cid-sRAtISOGzm .navbar.navbar-short {
  min-height: 60px;
}
@media (min-width: 992px) {
  .cid-sRAtISOGzm .navbar.navbar-short {
    min-height: 105px;
  }
}
.cid-sRAtISOGzm .navbar.navbar-short .navbar-logo img {
  height: 4rem !important;
}
.cid-sRAtISOGzm .navbar.navbar-short .navbar-brand {
  min-height: 60px;
  padding: 0;
}
.cid-sRAtISOGzm .navbar-brand {
  min-height: 70px;
  flex-shrink: 0;
  align-items: center;
  margin-right: 0;
  padding: 0;
  transition: all 0.3s;
  word-break: break-word;
  z-index: 1;
}
@media (min-width: 992px) {
  .cid-sRAtISOGzm .navbar-brand {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 40%;
    top: 50%;
    transform: translateY(-50%);
    width: 20%;
    min-height: 100%;
    z-index: 5;
  }
}
.cid-sRAtISOGzm .navbar-brand .navbar-caption {
  line-height: inherit !important;
  display: flex;
  flex-wrap: nowrap;
  color: #002549;
}
.cid-sRAtISOGzm .navbar-brand .navbar-caption span {
  line-height: inherit !important;
  font-weight: inherit !important;
  color: #fff000;
}
.cid-sRAtISOGzm .navbar-brand .navbar-caption:hover {
  color: #fff000;
}
.cid-sRAtISOGzm .navbar-brand .navbar-logo a {
  outline: none;
}
.cid-sRAtISOGzm .dropdown-item.active,
.cid-sRAtISOGzm .dropdown-item:active {
  background-color: transparent;
}
.cid-sRAtISOGzm .navbar-expand-lg .navbar-nav .nav-link {
  padding: 0;
}
.cid-sRAtISOGzm .nav-dropdown .link.dropdown-toggle {
  margin-right: 1.667em;
}
.cid-sRAtISOGzm .nav-dropdown .link.dropdown-toggle[aria-expanded="true"] {
  margin-right: 0;
  padding: 0.667em 1.667em;
}
.cid-sRAtISOGzm .navbar.navbar-expand-lg .dropdown .dropdown-menu {
  background: #f5811e;
}
.cid-sRAtISOGzm
  .navbar.navbar-expand-lg
  .dropdown
  .dropdown-menu
  .dropdown-submenu {
  margin: 0;
  left: 100%;
}
.cid-sRAtISOGzm .navbar .dropdown.open > .dropdown-menu {
  display: block;
}
.cid-sRAtISOGzm ul.navbar-nav {
  flex-wrap: wrap;
}
.cid-sRAtISOGzm .navbar-buttons {
  text-align: center;
}
@media (min-width: 992px) {
  .cid-sRAtISOGzm .navbar-buttons {
    width: 40%;
    text-align: right;
  }
}
.cid-sRAtISOGzm .navbar-buttons .btn {
  min-width: 144px;
  font-weight: 400;
}
.cid-sRAtISOGzm button.navbar-toggler {
  outline: none;
  width: 31px;
  height: 20px;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  align-self: center;
}
.cid-sRAtISOGzm button.navbar-toggler .hamburger span {
  position: absolute;
  right: 0;
  width: 30px;
  height: 2px;
  border-right: 5px;
  background-color: #000000;
}
.cid-sRAtISOGzm button.navbar-toggler .hamburger span:nth-child(1) {
  top: 0;
  transition: all 0.2s;
}
.cid-sRAtISOGzm button.navbar-toggler .hamburger span:nth-child(2) {
  top: 8px;
  transition: all 0.15s;
}
.cid-sRAtISOGzm button.navbar-toggler .hamburger span:nth-child(3) {
  top: 8px;
  transition: all 0.15s;
}
.cid-sRAtISOGzm button.navbar-toggler .hamburger span:nth-child(4) {
  top: 16px;
  transition: all 0.2s;
}
.cid-sRAtISOGzm nav.opened .hamburger span:nth-child(1) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.cid-sRAtISOGzm nav.opened .hamburger span:nth-child(2) {
  transform: rotate(45deg);
  transition: all 0.25s;
}
.cid-sRAtISOGzm nav.opened .hamburger span:nth-child(3) {
  transform: rotate(-45deg);
  transition: all 0.25s;
}
.cid-sRAtISOGzm nav.opened .hamburger span:nth-child(4) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.cid-sRAtISOGzm .navbar-dropdown {
  padding: 0 1rem;
  position: fixed;
}
.cid-sRAtISOGzm a.nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #002549;
}
.cid-sRAtISOGzm .icons-menu {
  flex-wrap: nowrap;
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.3rem;
  text-align: center;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cid-sRAtISOGzm .navbar {
    height: 60px;
  }
  .cid-sRAtISOGzm .navbar.opened {
    height: auto;
  }
  .cid-sRAtISOGzm .nav-item .nav-link:hover::before {
    width: 175%;
    max-width: calc(100% + 2rem);
    left: -1rem;
  }
}
@media screen and (-ms-high-contrast: active) and (min-width: 992px),
  (-ms-high-contrast: none) and (min-width: 992px) {
  .cid-sRAtISOGzm .navbar {
    min-height: 105px;
  }
}
.cid-sRAtISOGzm .ticker-container {
  width: 100%;
  overflow: hidden;
}
.cid-sRAtISOGzm .ticker-wrapper {
  width: 100%;
  padding-left: 100%;
  background-color: #000000;
}
@keyframes ticker {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
.cid-sRAtISOGzm .ticker-transition {
  display: inline-block;
  white-space: nowrap;
  padding-right: 100%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ticker;
  animation-duration: 60s;
}
.cid-sRAtISOGzm .ticker-transition:hover {
  animation-play-state: paused;
  cursor: pointer;
}
.cid-sRAtISOGzm .ticker-item {
  display: inline-block;
  padding: 0 2rem;
  color: #ffffff;
  font-size: 20px;
}
.cid-ubvM2GrR4M {
  padding-top: 0rem;
  padding-bottom: 0rem;
  background-color: #ffffff;
}
.cid-ubvM2GrR4M .orange-back {
  height: 4px;
  background-color: #f5811e;
}
.cid-ubvM2GrR4M .white-back {
  height: 3px;
  background-color: #ffffff;
}
.cid-ubvM2GrR4M img {
  border-radius: 0px;
  max-width: 666px;
  height: 98px;
  padding-top: 10px;
  padding-bottom: 10px;
}
@media (max-width: 992px) {
  .cid-ubvM2GrR4M img {
    width: 100%;
    height: 100%;
  }
}
.cid-ubvM2GrR4M .row {
  justify-content: center;
}
.cid-ubvM2GrR4M .ticker-container {
  width: 100%;
  overflow: hidden;
}
.cid-ubvM2GrR4M .ticker-wrapper {
  width: 100%;
  padding-left: 100%;
  background-color: #000000;
}
@keyframes ticker {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
.cid-ubvM2GrR4M .ticker-transition {
  display: inline-block;
  white-space: nowrap;
  padding-right: 100%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ticker;
  animation-duration: 120s;
}
.cid-ubvM2GrR4M .ticker-transition:hover {
  animation-play-state: paused;
  cursor: pointer;
}
.cid-ubvM2GrR4M .ticker-item {
  display: inline-block;
  padding: 0 2rem;
  color: #ffffff;
  font-size: 20px;
}
.cid-uaA1KqV6FL {
  padding-top: 2rem;
  padding-bottom: 0rem;
  background-color: #f5811e;
}
@media (max-width: 991px) {
  .cid-uaA1KqV6FL .btn {
    font-size: 12px;
    height: 26px;
    width: 140px;
    padding: 0px;
  }
}
.cid-uaA1KqV6FL .icons {
  height: 16px;
  width: 16px;
}
.cid-uaA1KqV6FL .valign-in-div {
  align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cid-uaA1KqV6FL .less-spacing {
  padding-bottom: 0px;
}
.cid-uaA1KqV6FL .small-font {
  font-size: 16px;
  font-weight: 400px;
  color: #ffffff;
}
@media (max-width: 768px) {
  .cid-uaA1KqV6FL .small-font {
    font-size: 11px;
  }
}
.cid-uaA1KqV6FL .row-height30 {
  height: 30px;
}
@media (max-width: 768px) {
  .cid-uaA1KqV6FL .row-height30 {
    height: 20px;
  }
}
.cid-uaA1KqV6FL .row-height40 {
  height: 40px;
}
@media (max-width: 768px) {
  .cid-uaA1KqV6FL .row-height40 {
    height: 10px;
    padding-bottom: 20px;
  }
}
.cid-uaA1KqV6FL .row-height50 {
  height: 50px;
}
@media (max-width: 768px) {
  .cid-uaA1KqV6FL .row-height50 {
    height: 10px;
    padding-bottom: 30px;
  }
}
.cid-uaA1KqV6FL .row-height50-bot {
  height: 50px;
  padding-bottom: 100px;
}
@media (max-width: 768px) {
  .cid-uaA1KqV6FL .row-height50-bot {
    height: 10px;
    padding-bottom: 50px;
  }
}
.cid-uaA1KqV6FL .row-height80 {
  height: 80px;
}
.cid-uaA1KqV6FL img,
.cid-uaA1KqV6FL .item-img {
  width: 100%;
}
.cid-uaA1KqV6FL .item:focus,
.cid-uaA1KqV6FL span:focus {
  outline: none;
}
.cid-uaA1KqV6FL .item-wrapper {
  position: relative;
}
.cid-uaA1KqV6FL .slide-content {
  position: relative;
  border-radius: 0px;
  background: #f5811e;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-flow: column nowrap;
}
@media (min-width: 992px) {
  .cid-uaA1KqV6FL .slide-content .item-content {
    padding: 2rem;
  }
}
@media (max-width: 991px) {
  .cid-uaA1KqV6FL .slide-content .item-content {
    padding: 1rem;
  }
}
.cid-uaA1KqV6FL .mbr-section-btn {
  margin-top: auto !important;
}
.cid-uaA1KqV6FL .mbr-section-title {
  color: #232323;
  text-align: left;
  padding-left: 55px;
}
.cid-uaA1KqV6FL .mbr-text,
.cid-uaA1KqV6FL .mbr-section-btn {
  text-align: center;
}
.cid-uaA1KqV6FL .item-title {
  text-align: center;
  color: #000000;
}
@media (max-width: 768px) {
  .cid-uaA1KqV6FL .item-title {
    font-size: 16px;
  }
}
.cid-uaA1KqV6FL .item-subtitle {
  text-align: left;
}
.cid-uaA1KqV6FL .embla__slide {
  display: flex;
  justify-content: center;
  position: relative;
  min-width: 410px;
  max-width: 410px;
}
@media (max-width: 768px) {
  .cid-uaA1KqV6FL .embla__slide {
    min-width: 70%;
    max-width: initial;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
.cid-uaA1KqV6FL .embla__button--next,
.cid-uaA1KqV6FL .embla__button--prev {
  display: flex;
}
.cid-uaA1KqV6FL .embla__button {
  top: 50%;
  width: 60px;
  height: 60px;
  margin-top: -1.5rem;
  font-size: 22px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  transition: all 0.3s;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
@media (max-width: 768px) {
  .cid-uaA1KqV6FL .embla__button {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .cid-uaA1KqV6FL .embla__button {
    display: none;
  }
}
.cid-uaA1KqV6FL .embla__button:disabled {
  cursor: default;
  display: none;
}
.cid-uaA1KqV6FL .embla__button:hover {
  background: #000;
  color: rgba(255, 255, 255, 0.5);
}
.cid-uaA1KqV6FL .embla__button.embla__button--prev {
  left: 0;
  margin-left: 2.5rem;
}
.cid-uaA1KqV6FL .embla__button.embla__button--next {
  right: 0;
  margin-right: 2.5rem;
}
@media (max-width: 767px) {
  .cid-uaA1KqV6FL .embla__button {
    top: auto;
  }
}
.cid-uaA1KqV6FL .embla {
  position: relative;
  width: 100%;
}
.cid-uaA1KqV6FL .embla__viewport {
  overflow: hidden;
  width: 100%;
}
.cid-uaA1KqV6FL .embla__viewport.is-draggable {
  cursor: grab;
}
.cid-uaA1KqV6FL .embla__viewport.is-dragging {
  cursor: grabbing;
}
.cid-uaA1KqV6FL .embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.cid-uayZ26S37e {
  padding-top: 2rem;
  padding-bottom: 1rem;
  background-color: #ffffff;
}
.cid-uayZ26S37e .btn {
  width: 200px;
}
.cid-uayZ26S37e .mbr-fallback-image.disabled {
  display: none;
}
.cid-uayZ26S37e .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-uayZ26S37e .container-fluid {
  padding: 0 40px;
}
@media (max-width: 992px) {
  .cid-uayZ26S37e .container-fluid {
    padding: 0 24px;
  }
}
@media (max-width: 992px) {
  .cid-uayZ26S37e .container {
    padding: 0 24px;
  }
}
.cid-uayZ26S37e .content-wrapper .mbr-section-title {
  margin-bottom: 28px;
}
.cid-uayZ26S37e .content-wrapper .text-wrap .mbr-text {
  display: inline-flex;
  width: 80%;
  margin-bottom: 18px;
}
@media (max-width: 1200px) {
  .cid-uayZ26S37e .content-wrapper .text-wrap .mbr-text {
    width: 70%;
  }
}
@media (max-width: 992px) {
  .cid-uayZ26S37e .content-wrapper .text-wrap .mbr-text {
    width: 100%;
  }
}
.cid-uayZ26S37e .mbr-section-title {
  color: #202020;
}
.cid-uayZ26S37e .mbr-text,
.cid-uayZ26S37e .text-wrap {
  color: #202020;
  text-align: center;
}
.cid-uayZ26S37e .mbr-section-title,
.cid-uayZ26S37e .mbr-section-btn {
  text-align: center;
}
.cid-uayZ26S37e .image-wrap {
  width: 546px;
  display: block;
  margin: auto;
}
@media (max-width: 992px) {
  .cid-uayZ26S37e .image-wrap {
    width: 95%;
  }
}
.cid-uaAlmhbDnQ {
  padding-top: 2rem;
  padding-bottom: 0rem;
  background-color: #f5811e;
}
@media (max-width: 991px) {
  .cid-uaAlmhbDnQ .btn {
    font-size: 12px;
    height: 26px;
    width: 140px;
    padding: 0px;
  }
}
.cid-uaAlmhbDnQ .icons {
  height: 16px;
  width: 16px;
}
.cid-uaAlmhbDnQ .valign-in-div {
  align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cid-uaAlmhbDnQ .less-spacing {
  padding-bottom: 0px;
}
.cid-uaAlmhbDnQ .small-font {
  font-size: 16px;
  font-weight: 400px;
  color: #ffffff;
}
@media (max-width: 768px) {
  .cid-uaAlmhbDnQ .small-font {
    font-size: 11px;
  }
}
.cid-uaAlmhbDnQ .row-height30 {
  height: 30px;
}
@media (max-width: 768px) {
  .cid-uaAlmhbDnQ .row-height30 {
    height: 20px;
  }
}
.cid-uaAlmhbDnQ .row-height40 {
  height: 40px;
}
@media (max-width: 768px) {
  .cid-uaAlmhbDnQ .row-height40 {
    height: 10px;
    padding-bottom: 20px;
  }
}
.cid-uaAlmhbDnQ .row-height50 {
  height: 50px;
}
@media (max-width: 768px) {
  .cid-uaAlmhbDnQ .row-height50 {
    height: 10px;
    padding-bottom: 30px;
  }
}
.cid-uaAlmhbDnQ .row-height50-bot {
  height: 50px;
  padding-bottom: 100px;
}
@media (max-width: 768px) {
  .cid-uaAlmhbDnQ .row-height50-bot {
    height: 10px;
    padding-bottom: 50px;
  }
}
.cid-uaAlmhbDnQ .row-height80 {
  height: 80px;
}
.cid-uaAlmhbDnQ img,
.cid-uaAlmhbDnQ .item-img {
  width: 100%;
}
.cid-uaAlmhbDnQ .item:focus,
.cid-uaAlmhbDnQ span:focus {
  outline: none;
}
.cid-uaAlmhbDnQ .item-wrapper {
  position: relative;
}
.cid-uaAlmhbDnQ .slide-content {
  position: relative;
  border-radius: 0px;
  background: #f5811e;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-flow: column nowrap;
}
@media (min-width: 992px) {
  .cid-uaAlmhbDnQ .slide-content .item-content {
    padding: 2rem;
  }
}
@media (max-width: 991px) {
  .cid-uaAlmhbDnQ .slide-content .item-content {
    padding: 1rem;
  }
}
.cid-uaAlmhbDnQ .mbr-section-btn {
  margin-top: auto !important;
}
.cid-uaAlmhbDnQ .mbr-section-title {
  color: #232323;
  text-align: left;
  padding-left: 55px;
}
.cid-uaAlmhbDnQ .mbr-text,
.cid-uaAlmhbDnQ .mbr-section-btn {
  text-align: center;
}
.cid-uaAlmhbDnQ .item-title {
  text-align: center;
  color: #000000;
}
@media (max-width: 768px) {
  .cid-uaAlmhbDnQ .item-title {
    font-size: 16px;
  }
}
.cid-uaAlmhbDnQ .item-subtitle {
  text-align: left;
}
.cid-uaAlmhbDnQ .embla__slide {
  display: flex;
  justify-content: center;
  position: relative;
  min-width: 410px;
  max-width: 410px;
}
@media (max-width: 768px) {
  .cid-uaAlmhbDnQ .embla__slide {
    min-width: 70%;
    max-width: initial;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
.cid-uaAlmhbDnQ .embla__button--next,
.cid-uaAlmhbDnQ .embla__button--prev {
  display: flex;
}
.cid-uaAlmhbDnQ .embla__button {
  top: 50%;
  width: 60px;
  height: 60px;
  margin-top: -1.5rem;
  font-size: 22px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  transition: all 0.3s;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
@media (max-width: 768px) {
  .cid-uaAlmhbDnQ .embla__button {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .cid-uaAlmhbDnQ .embla__button {
    display: none;
  }
}
.cid-uaAlmhbDnQ .embla__button:disabled {
  cursor: default;
  display: none;
}
.cid-uaAlmhbDnQ .embla__button:hover {
  background: #000;
  color: rgba(255, 255, 255, 0.5);
}
.cid-uaAlmhbDnQ .embla__button.embla__button--prev {
  left: 0;
  margin-left: 2.5rem;
}
.cid-uaAlmhbDnQ .embla__button.embla__button--next {
  right: 0;
  margin-right: 2.5rem;
}
@media (max-width: 767px) {
  .cid-uaAlmhbDnQ .embla__button {
    top: auto;
  }
}
.cid-uaAlmhbDnQ .embla {
  position: relative;
  width: 100%;
}
.cid-uaAlmhbDnQ .embla__viewport {
  overflow: hidden;
  width: 100%;
}
.cid-uaAlmhbDnQ .embla__viewport.is-draggable {
  cursor: grab;
}
.cid-uaAlmhbDnQ .embla__viewport.is-dragging {
  cursor: grabbing;
}
.cid-uaAlmhbDnQ .embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.cid-ucgBgmuenf {
  padding-top: 2rem;
  padding-bottom: 1rem;
  background-color: #ffffff;
}
.cid-ucgBgmuenf .mbr-fallback-image.disabled {
  display: none;
}
.cid-ucgBgmuenf .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
@media (max-width: 992px) {
  .cid-ucgBgmuenf .container {
    padding: 0 25px;
  }
}
.cid-ucgBgmuenf .row {
  justify-content: center;
}
.cid-ucgBgmuenf .row .card {
  justify-content: center;
}
@media (max-width: 992px) {
  .cid-ucgBgmuenf .image-wrapper {
    margin-bottom: 20px;
  }
}
.cid-ucgBgmuenf .image-wrapper img {
  height: 340px;
  width: 350px;
  object-fit: cover;
}
@media (max-width: 992px) {
  .cid-ucgBgmuenf .image-wrapper img {
    height: 243px;
    width: 250px;
    align: center;
    padding-top: 5px;
  }
}
.cid-ucgBgmuenf .form-wrapper {
  padding-left: 54px;
}
@media (max-width: 992px) {
  .cid-ucgBgmuenf .form-wrapper {
    padding: 0 12px;
  }
}
.cid-ucgBgmuenf .form-wrapper .dragArea.row {
  margin: 0;
}
.cid-ucgBgmuenf .form-wrapper .dragArea.row .mbr-section-title {
  margin-bottom: 20px !important;
}
.cid-ucgBgmuenf .form-wrapper .dragArea.row .mbr-text {
  margin-bottom: 20px !important;
}
.cid-ucgBgmuenf .form-wrapper .dragArea.row .form-group {
  margin-bottom: 20px !important;
}
.cid-ucgBgmuenf .form-wrapper .dragArea.row .form-group .form-control {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  line-height: 1.3;
  background-color: transparent;
  padding: 5px 0;
  box-shadow: none;
  border: none !important;
  border-bottom: 2px solid #e1e1e1 !important;
  border-radius: 0 !important;
}
.cid-ucgBgmuenf
  .form-wrapper
  .dragArea.row
  .form-group
  .form-control::placeholder {
  color: #e1e1e1;
}
.cid-ucgBgmuenf .form-wrapper .dragArea.row .form-group .form-control:hover,
.cid-ucgBgmuenf .form-wrapper .dragArea.row .form-group .form-control:focus {
  box-shadow: none;
  border: none !important;
  border-bottom: 1px solid #e1e1e1 !important;
}
.cid-ucgBgmuenf .form-wrapper .dragArea.row .form-group textarea {
  min-height: 124px;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}
@media (max-width: 992px) {
  .cid-ucgBgmuenf .form-wrapper .dragArea.row .form-group textarea {
    min-height: 190px;
  }
}
.cid-ucgBgmuenf .form-wrapper .dragArea.row .form-group .form-check {
  padding-left: 2.1em;
}
.cid-ucgBgmuenf
  .form-wrapper
  .dragArea.row
  .form-group
  .form-check
  .form-check-input {
  background-color: #e1e1e1;
  border-color: #e1e1e1;
}
.cid-ucgBgmuenf .form-wrapper .dragArea.row .mbr-section-btn {
  width: 200px;
}
.cid-ucgBgmuenf .mbr-section-title {
  color: #000000;
}
@media (max-width: 768px) {
  .cid-ucgBgmuenf .mbr-section-title {
    text-align: center;
  }
}
.cid-ucgBgmuenf .mbr-text {
  color: #000000;
}
.cid-ucgBgmuenf label {
  color: #000000;
}
.cid-ucgBgmuenf H2 {
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ucgBgmuenf .mbr-fonts-style {
    text-align: center;
  }
}
.cid-uaBnLgMTcC {
  padding-top: 1rem;
  padding-bottom: 0rem;
  background-color: #170038;
}
.cid-uaBnLgMTcC .btn {
  width: 200px;
}
.cid-uaBnLgMTcC img,
.cid-uaBnLgMTcC .item-img {
  width: 100%;
}
.cid-uaBnLgMTcC .item:focus,
.cid-uaBnLgMTcC span:focus {
  outline: none;
}
.cid-uaBnLgMTcC .item {
  cursor: pointer;
  margin-bottom: 2rem;
}
.cid-uaBnLgMTcC .row {
  margin-left: 0;
  margin-right: 0;
}
.cid-uaBnLgMTcC .item {
  padding: 0;
  margin: 0;
}
.cid-uaBnLgMTcC .item-wrapper {
  position: relative;
  border-radius: 4px;
  background: #170038;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}
@media (min-width: 992px) {
  .cid-uaBnLgMTcC .item-wrapper .item-content {
    padding: 2rem 2rem 0;
  }
  .cid-uaBnLgMTcC .item-wrapper .item-footer {
    padding: 0 2rem 2rem;
  }
}
@media (max-width: 991px) {
  .cid-uaBnLgMTcC .item-wrapper .item-content {
    padding: 1rem 1rem 0;
  }
  .cid-uaBnLgMTcC .item-wrapper .item-footer {
    padding: 0 1rem 1rem;
  }
}
.cid-uaBnLgMTcC .mbr-section-btn {
  margin-top: auto !important;
}
.cid-uaBnLgMTcC .mbr-section-title {
  color: #232323;
}
.cid-uaBnLgMTcC .mbr-text,
.cid-uaBnLgMTcC .mbr-section-btn {
  text-align: center;
}
.cid-uaBnLgMTcC .item-title {
  text-align: left;
}
.cid-uaBnLgMTcC .item-subtitle {
  text-align: left;
}
.cid-uaBnLgMTcC .H5 {
  color: #ffffff;
  text-align: center;
}
.cid-uaBnLgMTcC H5 {
  color: #ffffff;
  text-align: center;
}
.cid-uazZfrGObc {
  padding-top: 2rem;
  padding-bottom: 1rem;
  background-color: #ffffff;
}
.cid-uazZfrGObc .mbr-fallback-image.disabled {
  display: none;
}
.cid-uazZfrGObc .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-uazZfrGObc .container-fluid {
  padding: 0 40px;
}
@media (max-width: 992px) {
  .cid-uazZfrGObc .container-fluid {
    padding: 0 24px;
  }
}
@media (max-width: 992px) {
  .cid-uazZfrGObc .container {
    padding: 0 24px;
  }
}
.cid-uazZfrGObc .content-wrapper .mbr-section-title {
  margin-bottom: 28px;
}
.cid-uazZfrGObc .content-wrapper .text-wrap .mbr-text {
  display: inline-flex;
  width: 80%;
  margin-bottom: 18px;
}
@media (max-width: 1200px) {
  .cid-uazZfrGObc .content-wrapper .text-wrap .mbr-text {
    width: 70%;
  }
}
@media (max-width: 992px) {
  .cid-uazZfrGObc .content-wrapper .text-wrap .mbr-text {
    width: 100%;
  }
}
.cid-uazZfrGObc .mbr-section-title {
  color: #202020;
}
.cid-uazZfrGObc .mbr-text,
.cid-uazZfrGObc .text-wrap {
  color: #202020;
  text-align: center;
}
.cid-uazZfrGObc .mbr-section-title,
.cid-uazZfrGObc .mbr-section-btn {
  text-align: center;
}
.cid-uazZfrGObc .image-wrap {
  width: 758px;
  display: block;
  margin: auto;
}
@media (max-width: 992px) {
  .cid-uazZfrGObc .image-wrap {
    width: 100%;
  }
}
.cid-uaBv5QyoI5 {
  padding-top: 2rem;
  padding-bottom: 0rem;
  background-color: #f5811e;
}
.cid-uaBv5QyoI5 .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-uaBv5QyoI5 .btn {
    width: 150px;
    font-size: 12px;
  }
}
.cid-uaBv5QyoI5 .yellow-link {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}
.cid-uaBv5QyoI5 img,
.cid-uaBv5QyoI5 .item-img {
  width: 100%;
  max-width: 305px;
  object-fit: cover;
  object-position: center;
  height: 405px;
  border-radius: 10px;
}
.cid-uaBv5QyoI5 .item:focus,
.cid-uaBv5QyoI5 span:focus {
  outline: none;
}
.cid-uaBv5QyoI5 .item-wrapper {
  position: relative;
}
.cid-uaBv5QyoI5 .slide-content {
  position: relative;
  border-radius: 0px;
  background: #f5811e;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-flow: column nowrap;
}
@media (min-width: 992px) {
  .cid-uaBv5QyoI5 .slide-content .item-content {
    padding: 2rem 2rem 0;
  }
  .cid-uaBv5QyoI5 .slide-content .item-footer {
    padding: 0 2rem 2rem;
  }
}
@media (max-width: 991px) {
  .cid-uaBv5QyoI5 .slide-content .item-content {
    padding: 1rem 1rem 0;
  }
  .cid-uaBv5QyoI5 .slide-content .item-footer {
    padding: 0 1rem 1rem;
  }
}
.cid-uaBv5QyoI5 .mbr-section-btn {
  margin-top: auto !important;
}
.cid-uaBv5QyoI5 .mbr-section-title {
  color: #232323;
  text-align: left;
  padding-left: 55px;
}
@media (max-width: 991px) {
  .cid-uaBv5QyoI5 .mbr-section-title {
    padding-left: 20px;
  }
}
.cid-uaBv5QyoI5 .mbr-text,
.cid-uaBv5QyoI5 .mbr-section-btn {
  text-align: center;
}
.cid-uaBv5QyoI5 .item-title {
  text-align: left;
}
.cid-uaBv5QyoI5 .item-subtitle {
  text-align: left;
}
.cid-uaBv5QyoI5 .embla__slide {
  display: flex;
  justify-content: center;
  position: relative;
  min-width: 410px;
  max-width: 410px;
}
@media (max-width: 768px) {
  .cid-uaBv5QyoI5 .embla__slide {
    min-width: 70%;
    max-width: initial;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
.cid-uaBv5QyoI5 .embla__button--next,
.cid-uaBv5QyoI5 .embla__button--prev {
  display: flex;
}
.cid-uaBv5QyoI5 .embla__button {
  top: 50%;
  width: 60px;
  height: 60px;
  margin-top: -1.5rem;
  font-size: 22px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  transition: all 0.3s;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
@media (max-width: 768px) {
  .cid-uaBv5QyoI5 .embla__button {
    display: none;
  }
}
.cid-uaBv5QyoI5 .embla__button:disabled {
  cursor: default;
  display: none;
}
.cid-uaBv5QyoI5 .embla__button:hover {
  background: #000;
  color: rgba(255, 255, 255, 0.5);
}
.cid-uaBv5QyoI5 .embla__button.embla__button--prev {
  left: 0;
  margin-left: 2.5rem;
}
.cid-uaBv5QyoI5 .embla__button.embla__button--next {
  right: 0;
  margin-right: 2.5rem;
}
@media (max-width: 767px) {
  .cid-uaBv5QyoI5 .embla__button {
    top: auto;
  }
}
.cid-uaBv5QyoI5 .embla {
  position: relative;
  width: 100%;
}
.cid-uaBv5QyoI5 .embla__viewport {
  overflow: hidden;
  width: 100%;
}
.cid-uaBv5QyoI5 .embla__viewport.is-draggable {
  cursor: grab;
}
.cid-uaBv5QyoI5 .embla__viewport.is-dragging {
  cursor: grabbing;
}
.cid-uaBv5QyoI5 .embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.cid-uaBv5QyoI5 .mbr-section-subtitle {
  text-align: right;
}
.cid-uaHPdKaWi3 {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-image: url("/assets/twister/images/purple-back-3.png");
}
.cid-uaHPdKaWi3 .display-99 {
  font-family: "Montserrat", sans-serif;
  font-size: 60px;
  font-optical-sizing: auto;
  font-weight: 900;
  background: -webkit-linear-gradient(
    left,
    #f8d765,
    #c98924,
    #f8d765,
    #c98924,
    #f8d765,
    #c98924,
    #f8d765,
    #c98924,
    #f8d765,
    #c98924,
    #f8d765,
    #c98924,
    #f8d765,
    #c98924,
    #f8d765,
    #c98924,
    #f8d765,
    #c98924,
    #f8d765,
    #c98924,
    #f8d765
  );
  -webkit-background-clip: text;
  text-shadow: 0 0 3px #ff0000, 0 0 5px #0000ff;
}
.cid-uaHPdKaWi3 .mbr-fallback-image.disabled {
  display: none;
}
.cid-uaHPdKaWi3 .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-uaHPdKaWi3 .container-fluid {
  padding: 0 80px;
}
@media (max-width: 992px) {
  .cid-uaHPdKaWi3 .container-fluid {
    padding: 0 24px;
  }
}
@media (max-width: 992px) {
  .cid-uaHPdKaWi3 .container {
    padding: 0 24px;
  }
}
.cid-uaHPdKaWi3 .title-wrapper .mbr-emoji {
  margin-bottom: 0;
}
.cid-uaHPdKaWi3 .title-wrapper .mbr-section-title {
  margin-bottom: 64px;
}
@media (max-width: 992px) {
  .cid-uaHPdKaWi3 .title-wrapper .mbr-section-title {
    margin-bottom: 32px;
  }
}
.cid-uaHPdKaWi3 .title-wrapper .mbr-text {
  margin-bottom: 0;
}
.cid-uaHPdKaWi3 .title-wrapper .mbr-section-btn {
  margin-top: 64px;
}
@media (max-width: 992px) {
  .cid-uaHPdKaWi3 .title-wrapper .mbr-section-btn {
    margin-top: 32px;
  }
}
.cid-uaHPdKaWi3 .mbr-section-title {
  color: #262642;
}
.cid-uaHPdKaWi3 .mbr-text {
  line-height: 50px;
  color: #ff3399;
  text-align: center;
}
@media (max-width: 992px) {
  .cid-uaHPdKaWi3 .mbr-text {
    font-size: 32px;
  }
}
.cid-uaHPdKaWi3 .mbr-section-title,
.cid-uaHPdKaWi3 .mbr-section-btn {
  text-align: center;
}
.cid-uaHPdKaWi3 .mbr-emoji {
  text-align: right;
}
.cid-uaHPdKaWi3 .mbr-text,
.cid-uaHPdKaWi3 .mbr-section-btn {
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .cid-uaHPdKaWi3 {
    background-image: url("/assets/twister/images/purple-back-sm.png");
  }
}
@media (max-width: 768px) {
  .cid-uaHPdKaWi3 .image-block {
    width: 100% !important;
  }
}
.cid-uaMRnyq1DL {
  padding-top: 3rem;
  padding-bottom: 1rem;
  background-color: #f5811e;
}
.cid-uaMRnyq1DL .img-size {
  width: 92px;
}
.cid-uaMRnyq1DL .row-links {
  width: 100%;
  justify-content: center;
}
.cid-uaMRnyq1DL .social-row {
  width: 100%;
  justify-content: center;
}
.cid-uaMRnyq1DL .media-container-row {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cid-uaMRnyq1DL .media-container-row .foot-menu {
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 1rem;
}
.cid-uaMRnyq1DL .media-container-row .foot-menu li {
  padding: 0 1.5rem 1rem 1.5rem;
}
.cid-uaMRnyq1DL .media-container-row .foot-menu li:hover {
  color: #55ec25;
}
.cid-uaMRnyq1DL .media-container-row .foot-menu li p {
  margin: 0;
}
.cid-uaMRnyq1DL .media-container-row .social-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.cid-uaMRnyq1DL .media-container-row .social-list .mbr-iconfont-social {
  font-size: 1.5rem;
  color: #ffffff;
}
.cid-uaMRnyq1DL .media-container-row .social-list .soc-item {
  margin: 0 0.5rem;
}
.cid-uaMRnyq1DL .media-container-row .social-list a {
  margin: 0;
  opacity: 0.5;
  transition: 0.2s linear;
}
.cid-uaMRnyq1DL .media-container-row .social-list a:hover {
  opacity: 1;
}
@media (max-width: 767px) {
  .cid-uaMRnyq1DL .media-container-row .social-list {
    -webkit-justify-content: center;
    justify-content: center;
  }
}
.cid-uaMRnyq1DL .media-container-row .row-copirayt {
  word-break: break-word;
  width: 100%;
  justify-content: center;
}
.cid-uaMRnyq1DL .media-container-row .row-copirayt p {
  width: 100%;
}
@media (max-width: 767px) {
  .cid-uaMRnyq1DL .foot-menu {
    display: flex;
    flex-direction: column;
  }
}
.cid-uaMRnyq1DL .media-container-row .mbr-text {
  color: #c8c8c8;
}
.cid-uaNUoKpdO8 {
  padding-top: 0rem;
  padding-bottom: 0rem;
  background-color: #f5811e;
}
.cid-uaNUoKpdO8 .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-uaNUoKpdO8 .btn {
    width: 150px;
    font-size: 12px;
  }
}
.cid-uaNUoKpdO8 .ruler {
  height: 1px;
  background-color: #ffffff;
  width: 90%;
  margin: auto;
}
.cid-uaNUoKpdO8 .container {
  max-width: 1312px;
}
.cid-uaNUoKpdO8 .row {
  align-items: center;
  padding: 0 12px;
}
@media (min-width: 992px) {
  .cid-uaNUoKpdO8 .row {
    padding: 0 16px;
  }
}
.cid-uaNUoKpdO8 .content-wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 5rem;
  background-color: #f5811e;
}
@media (max-width: 991px) {
  .cid-uaNUoKpdO8 .content-wrap {
    padding: 10px 32px;
  }
}
@media (max-width: 567px) {
  .cid-uaNUoKpdO8 .content-wrap {
    padding: 10px 20px;
  }
}
.cid-uaNUoKpdO8 .image-wrapper {
  display: flex;
  justify-content: center;
}
@media (max-width: 991px) {
  .cid-uaNUoKpdO8 .image-wrapper {
    padding-top: 0.5rem;
  }
}
@media (min-width: 992px) {
  .cid-uaNUoKpdO8 .image-wrapper {
    padding-left: 1rem;
  }
}
@media (max-width: 991px) {
  .cid-uaNUoKpdO8 .col-text {
    padding-bottom: 1rem;
  }
}
@media (min-width: 992px) {
  .cid-uaNUoKpdO8 .col-text {
    padding-right: 1rem;
  }
}
.cid-uaNUoKpdO8 .text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cid-uaNUoKpdO8 .mbr-text {
  color: #ffffff;
  width: 100%;
}
.cid-uaNUoKpdO8 .btn-container {
  width: 100%;
}
@media (min-width: 992px) {
  .cid-uaNUoKpdO8 .btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 992px) {
  .cid-uaNUoKpdO8 .mbr-section-btn {
    text-align: center !important;
  }
}
@media (max-width: 575px) {
  .cid-uaNUoKpdO8 .mbr-section-btn {
    display: flex;
    flex-wrap: wrap;
  }
  .cid-uaNUoKpdO8 .mbr-section-btn .btn {
    width: 100%;
  }
}
.cid-uaNUoKpdO8 .mbr-fallback-image.disabled {
  display: none;
}
.cid-uaNUoKpdO8 .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-uaNUoKpdO8 .mbr-text,
.cid-uaNUoKpdO8 .mbr-section-btn {
  text-align: left;
  color: #000000;
  vertical-align: center;
}
@media (max-width: 768px) {
  .cid-uaNUoKpdO8 .mbr-text,
  .cid-uaNUoKpdO8 .mbr-section-btn {
    text-align: center;
  }
}
.cid-ub8tsnMtET .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-ub8tsnMtET .btn {
    width: 180px;
    font-size: 12px;
  }
}
.cid-ub8tsnMtET.popup-builder {
  background-color: #ffffff;
}
.cid-ub8tsnMtET.popup-builder .modal {
  position: relative;
  display: block;
  z-index: 1;
}
.cid-ub8tsnMtET.popup-builder .modal-dialog {
  margin-top: 60px;
  margin-bottom: 60px;
}
.cid-ub8tsnMtET .modal-content,
.cid-ub8tsnMtET .modal-dialog {
  height: auto;
}
.cid-ub8tsnMtET .form-wrapper .input-group-btn {
  margin-right: auto;
  margin-left: auto;
}
.cid-ub8tsnMtET .form-wrapper .input-group-btn .btn {
  margin: 0 !important;
}
@media (min-width: 769px) {
  .cid-ub8tsnMtET .form-wrapper .mbr-form .form-group,
  .cid-ub8tsnMtET .form-wrapper .mbr-form .input-group-btn {
    padding: 0 0.5rem;
  }
}
.cid-ub8tsnMtET .card-img {
  width: 100%;
  margin: auto;
  border-radius: 0;
}
.cid-ub8tsnMtET .mbr-figure img {
  display: block;
  width: 100%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  -webkit-align-self: center;
  align-self: center;
}
.cid-ub8tsnMtET .mbr-text {
  text-align: center;
  color: #ffffff;
}
.cid-ub8tsnMtET .pt-0 {
  padding-top: 0 !important;
}
.cid-ub8tsnMtET .pb-0 {
  padding-bottom: 0 !important;
}
.cid-ub8tsnMtET .form-content {
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.cid-ub8tsnMtET .mbr-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}
.cid-ub8tsnMtET .modal-open {
  overflow: hidden;
}
.cid-ub8tsnMtET .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.cid-ub8tsnMtET .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.cid-ub8tsnMtET .modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.cid-ub8tsnMtET .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
.cid-ub8tsnMtET .modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.cid-ub8tsnMtET .modal-dialog-centered {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}
.cid-ub8tsnMtET .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}
.cid-ub8tsnMtET .modal-content {
  background: #252525;
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: none;
  outline: 0;
  -webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.cid-ub8tsnMtET .margin-center-pos {
  margin-top: auto;
  margin-bottom: auto;
}
.cid-ub8tsnMtET .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.cid-ub8tsnMtET .modal-backdrop.fade {
  opacity: 0;
}
.cid-ub8tsnMtET .modal-backdrop.show {
  opacity: 0.5;
}
.cid-ub8tsnMtET .modal-header {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: none;
}
@media (min-width: 992px) {
  .cid-ub8tsnMtET .modal-header {
    padding: 2rem 2rem 1rem;
  }
}
@media (max-width: 991px) {
  .cid-ub8tsnMtET .modal-header {
    padding: 1rem;
  }
}
.cid-ub8tsnMtET .modal-header .close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
  opacity: 0.75;
}
.cid-ub8tsnMtET .modal-header .close svg {
  fill: #ffffff;
}
.cid-ub8tsnMtET .modal-header .close:hover {
  opacity: 1;
}
.cid-ub8tsnMtET .modal-header .close:focus {
  outline: none;
}
.cid-ub8tsnMtET .modal-title {
  line-height: 1.5;
  width: 100%;
  margin: 0;
  text-align: center;
  color: #ffffff;
  font-weight: 100;
}
.cid-ub8tsnMtET .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
@media (min-width: 992px) {
  .cid-ub8tsnMtET .modal-body {
    padding: 1rem 2rem 2rem;
  }
}
@media (max-width: 991px) {
  .cid-ub8tsnMtET .modal-body {
    padding: 1rem;
  }
}
.cid-ub8tsnMtET .modal-footer {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-top: none;
  text-align: center;
}
@media (min-width: 992px) {
  .cid-ub8tsnMtET .modal-footer {
    padding: 0rem 2rem 2rem;
  }
}
@media (max-width: 991px) {
  .cid-ub8tsnMtET .modal-footer {
    padding: 1rem;
  }
}
.cid-ub8tsnMtET .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .cid-ub8tsnMtET .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .cid-ub8tsnMtET .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .cid-ub8tsnMtET .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .cid-ub8tsnMtET .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .cid-ub8tsnMtET .modal-lg,
  .cid-ub8tsnMtET .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .cid-ub8tsnMtET .modal-xl {
    max-width: 1140px;
  }
}
.cid-ub8tsnMtET .row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.cid-ub8tsnMtET .col-md-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.cid-ub8tsnMtET .form-group {
  margin-bottom: 1rem;
}
.cid-ub8tsnMtET .form-control {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.cid-ub8tsnMtET .col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.cid-ub8tsnMtET .col-md-auto {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.cid-ub8tsnMtET .mbr-section-btn {
  margin: 0;
}
.cid-ub8tsnMtET .mbr-section-btn .btn {
  margin: 0;
}
.cid-ub8ttMltaP .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-ub8ttMltaP .btn {
    width: 180px;
    font-size: 12px;
  }
}
.cid-ub8ttMltaP.popup-builder {
  background-color: #ffffff;
}
.cid-ub8ttMltaP.popup-builder .modal {
  position: relative;
  display: block;
  z-index: 1;
}
.cid-ub8ttMltaP.popup-builder .modal-dialog {
  margin-top: 60px;
  margin-bottom: 60px;
}
.cid-ub8ttMltaP .modal-content,
.cid-ub8ttMltaP .modal-dialog {
  height: auto;
}
.cid-ub8ttMltaP .form-wrapper .input-group-btn {
  margin-right: auto;
  margin-left: auto;
}
.cid-ub8ttMltaP .form-wrapper .input-group-btn .btn {
  margin: 0 !important;
}
@media (min-width: 769px) {
  .cid-ub8ttMltaP .form-wrapper .mbr-form .form-group,
  .cid-ub8ttMltaP .form-wrapper .mbr-form .input-group-btn {
    padding: 0 0.5rem;
  }
}
.cid-ub8ttMltaP .card-img {
  width: 100%;
  margin: auto;
  border-radius: 0;
}
.cid-ub8ttMltaP .mbr-figure img {
  display: block;
  width: 100%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  -webkit-align-self: center;
  align-self: center;
}
.cid-ub8ttMltaP .mbr-text {
  text-align: center;
  color: #ffffff;
}
.cid-ub8ttMltaP .pt-0 {
  padding-top: 0 !important;
}
.cid-ub8ttMltaP .pb-0 {
  padding-bottom: 0 !important;
}
.cid-ub8ttMltaP .form-content {
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.cid-ub8ttMltaP .mbr-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}
.cid-ub8ttMltaP .modal-open {
  overflow: hidden;
}
.cid-ub8ttMltaP .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.cid-ub8ttMltaP .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.cid-ub8ttMltaP .modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.cid-ub8ttMltaP .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
.cid-ub8ttMltaP .modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.cid-ub8ttMltaP .modal-dialog-centered {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}
.cid-ub8ttMltaP .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}
.cid-ub8ttMltaP .modal-content {
  background: #252525;
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: none;
  outline: 0;
  -webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.cid-ub8ttMltaP .margin-center-pos {
  margin-top: auto;
  margin-bottom: auto;
}
.cid-ub8ttMltaP .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.cid-ub8ttMltaP .modal-backdrop.fade {
  opacity: 0;
}
.cid-ub8ttMltaP .modal-backdrop.show {
  opacity: 0.5;
}
.cid-ub8ttMltaP .modal-header {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: none;
}
@media (min-width: 992px) {
  .cid-ub8ttMltaP .modal-header {
    padding: 2rem 2rem 1rem;
  }
}
@media (max-width: 991px) {
  .cid-ub8ttMltaP .modal-header {
    padding: 1rem;
  }
}
.cid-ub8ttMltaP .modal-header .close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
  opacity: 0.75;
}
.cid-ub8ttMltaP .modal-header .close svg {
  fill: #ffffff;
}
.cid-ub8ttMltaP .modal-header .close:hover {
  opacity: 1;
}
.cid-ub8ttMltaP .modal-header .close:focus {
  outline: none;
}
.cid-ub8ttMltaP .modal-title {
  line-height: 1.5;
  width: 100%;
  margin: 0;
  text-align: center;
  color: #ffffff;
  font-weight: 100;
}
.cid-ub8ttMltaP .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
@media (min-width: 992px) {
  .cid-ub8ttMltaP .modal-body {
    padding: 1rem 2rem 2rem;
  }
}
@media (max-width: 991px) {
  .cid-ub8ttMltaP .modal-body {
    padding: 1rem;
  }
}
.cid-ub8ttMltaP .modal-footer {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-top: none;
  text-align: center;
}
@media (min-width: 992px) {
  .cid-ub8ttMltaP .modal-footer {
    padding: 2rem 2rem;
  }
}
@media (max-width: 991px) {
  .cid-ub8ttMltaP .modal-footer {
    padding: 1rem;
  }
}
.cid-ub8ttMltaP .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .cid-ub8ttMltaP .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .cid-ub8ttMltaP .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .cid-ub8ttMltaP .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .cid-ub8ttMltaP .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .cid-ub8ttMltaP .modal-lg,
  .cid-ub8ttMltaP .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .cid-ub8ttMltaP .modal-xl {
    max-width: 1140px;
  }
}
.cid-ub8ttMltaP .row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.cid-ub8ttMltaP .col-md-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.cid-ub8ttMltaP .form-group {
  margin-bottom: 1rem;
}
.cid-ub8ttMltaP .form-control {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.cid-ub8ttMltaP .col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.cid-ub8ttMltaP .col-md-auto {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.cid-ub8ttMltaP .mbr-section-btn {
  margin: 0;
}
.cid-ub8ttMltaP .mbr-section-btn .btn {
  margin: 0;
}
.cid-ub8ttMltaP LABEL {
  color: #ffffff;
}
.cid-ubNfiYTgCA {
  padding-top: 2rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubNfiYTgCA .mbr-fallback-image.disabled {
  display: none;
}
.cid-ubNfiYTgCA .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-ubNfiYTgCA .mbr-section-title {
  text-align: center;
}
@media (max-width: 768px) {
  .cid-ubNfiYTgCA .mbr-section-title {
    line-height: 25px;
  }
}
.cid-ubK3tn1Rtc {
  padding-top: 0rem;
  padding-bottom: 2rem;
  background-color: #ffc092;
}
.cid-ubK3tn1Rtc .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-ubK3tn1Rtc .btn {
    width: 160px;
    font-size: 12px;
  }
}
.cid-ubK3tn1Rtc .icons {
  height: 16px;
  width: 16px;
}
.cid-ubK3tn1Rtc .valign-in-div {
  align: center;
  display: flex;
  justify-content: left;
  align-items: left;
}
@media (max-width: 768px) {
  .cid-ubK3tn1Rtc .valign-in-div {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }
}
.cid-ubK3tn1Rtc .less-spacing {
  padding-bottom: 0px;
}
.cid-ubK3tn1Rtc .small-font {
  font-size: 16px;
  font-weight: 400px;
  color: #000000;
}
@media (max-width: 768px) {
  .cid-ubK3tn1Rtc .small-font {
    font-size: 11px;
    text-align: center;
  }
}
.cid-ubK3tn1Rtc .width-320 {
  width: 320px;
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .cid-ubK3tn1Rtc .width-320 {
    text-align: center;
  }
}
.cid-ubK3tn1Rtc .mbr-fallback-image.disabled {
  display: none;
}
.cid-ubK3tn1Rtc .container {
  max-width: 1300px;
}
.cid-ubK3tn1Rtc .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-ubK3tn1Rtc .text-wrapper {
  max-width: 700px;
  margin: auto;
}
@media (min-width: 992px) {
  .cid-ubK3tn1Rtc .text-wrapper {
    padding: 0 2rem;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .cid-ubK3tn1Rtc .image-wrapper {
    margin-bottom: 0rem;
  }
}
.cid-ubK3tn1Rtc .row {
  align-items: center;
}
@media (max-width: 991px) {
  .cid-ubK3tn1Rtc .image-wrapper {
    padding: 1.5rem;
  }
}
.cid-ubK3tn1Rtc .mbr-section-title {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubK3tn1Rtc .mbr-section-title {
    text-align: center;
  }
}
.cid-ubK3tn1Rtc .mbr-text {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubK3tn1Rtc .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-ubK3tn1Rtc .mbr-section-btn {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubK3tn1Rtc .mbr-section-btn {
    text-align: center;
    padding-top: 1px;
  }
}
.cid-ubK3tn1Rtc .mbr-section-subtitle {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubK3tn1Rtc .mbr-section-subtitle {
    text-align: center;
  }
}
.cid-ubNfOtIDFY {
  padding-top: 2rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubNfOtIDFY .mbr-fallback-image.disabled {
  display: none;
}
.cid-ubNfOtIDFY .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-ubNfOtIDFY .mbr-section-title {
  text-align: center;
}
@media (max-width: 768px) {
  .cid-ubNfOtIDFY .mbr-section-title {
    line-height: 25px;
  }
}
.cid-ubN49dL2Z9 {
  padding-top: 0rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubN49dL2Z9 .orange-back {
  height: 4px;
  background-color: #f5811e;
}
.cid-ubN49dL2Z9 .white-back {
  height: 3px;
  background-color: #ffffff;
}
.cid-ubN49dL2Z9 .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-ubN49dL2Z9 .btn {
    width: 160px;
    font-size: 12px;
  }
}
.cid-ubN49dL2Z9 .icons {
  height: 16px;
  width: 16px;
}
.cid-ubN49dL2Z9 .valign-in-div {
  align: center;
  display: flex;
  justify-content: left;
  align-items: left;
}
@media (max-width: 768px) {
  .cid-ubN49dL2Z9 .valign-in-div {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }
}
.cid-ubN49dL2Z9 .less-spacing {
  padding-bottom: 0px;
}
.cid-ubN49dL2Z9 .small-font {
  font-size: 16px;
  font-weight: 400px;
  color: #000000;
}
@media (max-width: 768px) {
  .cid-ubN49dL2Z9 .small-font {
    font-size: 11px;
    text-align: center;
  }
}
.cid-ubN49dL2Z9 .width-320 {
  width: 320px;
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .cid-ubN49dL2Z9 .width-320 {
    text-align: center;
  }
}
.cid-ubN49dL2Z9 .mbr-fallback-image.disabled {
  display: none;
}
.cid-ubN49dL2Z9 .container {
  max-width: 1300px;
}
.cid-ubN49dL2Z9 .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-ubN49dL2Z9 .text-wrapper {
  max-width: 700px;
  margin: auto;
}
@media (min-width: 992px) {
  .cid-ubN49dL2Z9 .text-wrapper {
    padding: 0 2rem;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .cid-ubN49dL2Z9 .image-wrapper {
    margin-bottom: 0rem;
  }
}
.cid-ubN49dL2Z9 .row {
  align-items: center;
}
@media (max-width: 991px) {
  .cid-ubN49dL2Z9 .image-wrapper {
    padding: 1.5rem;
  }
}
.cid-ubN49dL2Z9 .mbr-section-title {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubN49dL2Z9 .mbr-section-title {
    text-align: center;
  }
}
.cid-ubN49dL2Z9 .mbr-text {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubN49dL2Z9 .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-ubN49dL2Z9 .mbr-section-btn {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubN49dL2Z9 .mbr-section-btn {
    text-align: center;
    padding-top: 1px;
    padding-bottom: 30px;
  }
}
.cid-ubN49dL2Z9 .mbr-section-subtitle {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubN49dL2Z9 .mbr-section-subtitle {
    text-align: center;
  }
}
.cid-ubN631SyzY {
  padding-top: 2rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubN631SyzY .orange-back {
  height: 4px;
  background-color: #f5811e;
}
.cid-ubN631SyzY .white-back {
  height: 3px;
  background-color: #ffffff;
}
.cid-ubN631SyzY .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-ubN631SyzY .btn {
    width: 160px;
    font-size: 12px;
  }
}
.cid-ubN631SyzY .icons {
  height: 16px;
  width: 16px;
}
.cid-ubN631SyzY .valign-in-div {
  align: center;
  display: flex;
  justify-content: left;
  align-items: left;
}
@media (max-width: 768px) {
  .cid-ubN631SyzY .valign-in-div {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }
}
.cid-ubN631SyzY .less-spacing {
  padding-bottom: 0px;
}
.cid-ubN631SyzY .small-font {
  font-size: 16px;
  font-weight: 400px;
  color: #000000;
}
@media (max-width: 768px) {
  .cid-ubN631SyzY .small-font {
    font-size: 11px;
    text-align: center;
  }
}
.cid-ubN631SyzY .width-320 {
  width: 320px;
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .cid-ubN631SyzY .width-320 {
    text-align: center;
  }
}
.cid-ubN631SyzY .mbr-fallback-image.disabled {
  display: none;
}
.cid-ubN631SyzY .container {
  max-width: 1300px;
}
.cid-ubN631SyzY .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-ubN631SyzY .text-wrapper {
  max-width: 700px;
  margin: auto;
}
@media (min-width: 992px) {
  .cid-ubN631SyzY .text-wrapper {
    padding: 0 2rem;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .cid-ubN631SyzY .image-wrapper {
    margin-bottom: 0rem;
  }
}
.cid-ubN631SyzY .row {
  align-items: center;
}
@media (max-width: 991px) {
  .cid-ubN631SyzY .image-wrapper {
    padding: 1.5rem;
  }
}
.cid-ubN631SyzY .mbr-section-title {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubN631SyzY .mbr-section-title {
    text-align: center;
  }
}
.cid-ubN631SyzY .mbr-text {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubN631SyzY .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-ubN631SyzY .mbr-section-btn {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubN631SyzY .mbr-section-btn {
    text-align: center;
    padding-top: 1px;
    padding-bottom: 30px;
  }
}
.cid-ubN631SyzY .mbr-section-subtitle {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubN631SyzY .mbr-section-subtitle {
    text-align: center;
  }
}
.promotion-section {
  padding-top: 2rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.promotion-section .orange-back {
  height: 4px;
  background-color: #f5811e;
}
.promotion-section .white-back {
  height: 3px;
  background-color: #ffffff;
}
.promotion-section .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .promotion-section .btn {
    width: 160px;
    font-size: 12px;
  }
}
.promotion-section .icons {
  height: 16px;
  width: 16px;
}
.promotion-section .valign-in-div {
  align: center;
  display: flex;
  justify-content: left;
  align-items: left;
}
@media (max-width: 768px) {
  .promotion-section .valign-in-div {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }
}
.promotion-section .less-spacing {
  padding-bottom: 0px;
}
.promotion-section .small-font {
  font-size: 16px;
  font-weight: 400px;
  color: #000000;
}
@media (max-width: 768px) {
  .promotion-section .small-font {
    font-size: 11px;
    text-align: center;
  }
}
.promotion-section .width-320 {
  width: 320px;
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .promotion-section .width-320 {
    text-align: center;
  }
}
.promotion-section .mbr-fallback-image.disabled {
  display: none;
}
.promotion-section .container {
  max-width: 1300px;
}
.promotion-section .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.promotion-section .text-wrapper {
  max-width: 700px;
  margin: auto;
}
@media (min-width: 992px) {
  .promotion-section .text-wrapper {
    padding: 0 2rem;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .promotion-section .image-wrapper {
    margin-bottom: 0rem;
  }
}
.promotion-section .row {
  align-items: center;
}
@media (max-width: 991px) {
  .promotion-section .image-wrapper {
    padding: 1.5rem;
  }
}
.promotion-section .mbr-section-title {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .promotion-section .mbr-section-title {
    text-align: center;
  }
}
.promotion-section .mbr-text {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .promotion-section .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.promotion-section .mbr-section-btn {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .promotion-section .mbr-section-btn {
    text-align: center;
    padding-top: 1px;
    padding-bottom: 30px;
  }
}
.promotion-section .mbr-section-subtitle {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .promotion-section .mbr-section-subtitle {
    text-align: center;
  }
}
.cid-ubN6636xvj {
  padding-top: 2rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubN6636xvj .orange-back {
  height: 4px;
  background-color: #f5811e;
}
.cid-ubN6636xvj .white-back {
  height: 3px;
  background-color: #ffffff;
}
.cid-ubN6636xvj .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-ubN6636xvj .btn {
    width: 160px;
    font-size: 12px;
  }
}
.cid-ubN6636xvj .icons {
  height: 16px;
  width: 16px;
}
.cid-ubN6636xvj .valign-in-div {
  align: center;
  display: flex;
  justify-content: left;
  align-items: left;
}
@media (max-width: 768px) {
  .cid-ubN6636xvj .valign-in-div {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }
}
.cid-ubN6636xvj .less-spacing {
  padding-bottom: 0px;
}
.cid-ubN6636xvj .small-font {
  font-size: 16px;
  font-weight: 400px;
  color: #000000;
}
@media (max-width: 768px) {
  .cid-ubN6636xvj .small-font {
    font-size: 11px;
    text-align: center;
  }
}
.cid-ubN6636xvj .width-320 {
  width: 320px;
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .cid-ubN6636xvj .width-320 {
    text-align: center;
  }
}
.cid-ubN6636xvj .mbr-fallback-image.disabled {
  display: none;
}
.cid-ubN6636xvj .container {
  max-width: 1300px;
}
.cid-ubN6636xvj .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-ubN6636xvj .text-wrapper {
  max-width: 700px;
  margin: auto;
}
@media (min-width: 992px) {
  .cid-ubN6636xvj .text-wrapper {
    padding: 0 2rem;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .cid-ubN6636xvj .image-wrapper {
    margin-bottom: 0rem;
  }
}
.cid-ubN6636xvj .row {
  align-items: center;
}
@media (max-width: 991px) {
  .cid-ubN6636xvj .image-wrapper {
    padding: 1.5rem;
  }
}
.cid-ubN6636xvj .mbr-section-title {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubN6636xvj .mbr-section-title {
    text-align: center;
  }
}
.cid-ubN6636xvj .mbr-text {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubN6636xvj .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-ubN6636xvj .mbr-section-btn {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubN6636xvj .mbr-section-btn {
    text-align: center;
    padding-top: 1px;
    padding-bottom: 30px;
  }
}
.cid-ubN6636xvj .mbr-section-subtitle {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubN6636xvj .mbr-section-subtitle {
    text-align: center;
  }
}
.cid-ubNimk6XKq {
  padding-top: 2rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubNimk6XKq .mbr-fallback-image.disabled {
  display: none;
}
.cid-ubNimk6XKq .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-ubNimk6XKq .mbr-section-title {
  text-align: center;
}
@media (max-width: 768px) {
  .cid-ubNimk6XKq .mbr-section-title {
    line-height: 25px;
  }
}
.cid-ubNiYBuf2l {
  padding-top: 1rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubNiYBuf2l .mbr-section-subtitle {
  text-align: center;
}
.cid-ubNiYBuf2l .mbr-text {
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubNiYBuf2l .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-ubNkhcLYZS {
  padding-top: 1rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubNkhcLYZS .mbr-section-subtitle {
  text-align: center;
}
.cid-ubNkhcLYZS .mbr-text {
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubNkhcLYZS .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-ubNl48B2CA {
  padding-top: 1rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubNl48B2CA .mbr-section-subtitle {
  text-align: center;
}
.cid-ubNl48B2CA .mbr-text {
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubNl48B2CA .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-ubNl5vnfV8 {
  padding-top: 1rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubNl5vnfV8 .mbr-section-subtitle {
  text-align: center;
}
.cid-ubNl5vnfV8 .mbr-text {
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubNl5vnfV8 .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-ubNl6lUtKU {
  padding-top: 1rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubNl6lUtKU .mbr-section-subtitle {
  text-align: center;
}
.cid-ubNl6lUtKU .mbr-text {
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubNl6lUtKU .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-ubNmQtUayu {
  padding-top: 0rem;
  padding-bottom: 1rem;
  background-color: #ffc092;
}
.cid-ubNmQtUayu .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-ubNmQtUayu .btn {
    width: 160px;
    font-size: 12px;
  }
}
.cid-ubNoRNeXTP {
  padding-top: 2rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubNoRNeXTP .mbr-fallback-image.disabled {
  display: none;
}
.cid-ubNoRNeXTP .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-ubNoRNeXTP .mbr-section-title {
  text-align: center;
}
@media (max-width: 768px) {
  .cid-ubNoRNeXTP .mbr-section-title {
    line-height: 25px;
  }
}
.cid-ubNoYWDPqK {
  padding-top: 1rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubNoYWDPqK .mbr-section-subtitle {
  text-align: center;
}
.cid-ubNoYWDPqK .mbr-text {
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubNoYWDPqK .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .cid-ubNoYWDPqK .padding-less {
    padding-left: 15px;
    text-align: left;
  }
}
.cid-ubNp6qqZjO {
  padding-top: 0rem;
  padding-bottom: 1rem;
  background-color: #ffc092;
}
.cid-ubNp6qqZjO .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-ubNp6qqZjO .btn {
    width: 160px;
    font-size: 12px;
  }
}
.cid-ubNvhotoZc {
  padding-top: 2rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubNvhotoZc .mbr-fallback-image.disabled {
  display: none;
}
.cid-ubNvhotoZc .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-ubNvhotoZc .mbr-section-title {
  text-align: center;
}
@media (max-width: 768px) {
  .cid-ubNvhotoZc .mbr-section-title {
    line-height: 25px;
  }
}
.cid-ubNvrocRg9 {
  padding-top: 1rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubNvrocRg9 .mbr-section-subtitle {
  text-align: center;
}
.cid-ubNvrocRg9 .mbr-text {
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubNvrocRg9 .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-ubNwMyieur {
  padding-top: 0rem;
  padding-bottom: 1rem;
  background-color: #ffc092;
}
.cid-ubNwMyieur .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-ubNwMyieur .btn {
    width: 160px;
    font-size: 12px;
  }
}
.cid-ubNx1pjA1f {
  padding-top: 2rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubNx1pjA1f .mbr-fallback-image.disabled {
  display: none;
}
.cid-ubNx1pjA1f .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-ubNx1pjA1f .mbr-section-title {
  text-align: center;
}
@media (max-width: 768px) {
  .cid-ubNx1pjA1f .mbr-section-title {
    line-height: 25px;
  }
}
.cid-ubNx2ZBEUe {
  padding-top: 1rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubNx2ZBEUe .mbr-section-subtitle {
  text-align: center;
}
.cid-ubNx2ZBEUe .mbr-text {
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubNx2ZBEUe .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-ubNx5wc7T5 {
  padding-top: 0rem;
  padding-bottom: 1rem;
  background-color: #ffc092;
}
.cid-ubNx5wc7T5 .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-ubNx5wc7T5 .btn {
    width: 160px;
    font-size: 12px;
  }
}
.cid-ubNzdnnmnt {
  padding-top: 2rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubNzdnnmnt .mbr-fallback-image.disabled {
  display: none;
}
.cid-ubNzdnnmnt .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-ubNzdnnmnt .mbr-section-title {
  text-align: center;
}
@media (max-width: 768px) {
  .cid-ubNzdnnmnt .mbr-section-title {
    line-height: 25px;
  }
}
.cid-ubNzkrR0pb {
  padding-top: 1rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubNzkrR0pb .mbr-section-subtitle {
  text-align: center;
}
.cid-ubNzkrR0pb .mbr-text {
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubNzkrR0pb .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-ubNzm8nuzp {
  padding-top: 0rem;
  padding-bottom: 1rem;
  background-color: #ffc092;
}
.cid-ubNzm8nuzp .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-ubNzm8nuzp .btn {
    width: 160px;
    font-size: 12px;
  }
}
.cid-ubNA6io75G {
  padding-top: 2rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubNA6io75G .mbr-fallback-image.disabled {
  display: none;
}
.cid-ubNA6io75G .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-ubNA6io75G .mbr-section-title {
  text-align: center;
}
@media (max-width: 768px) {
  .cid-ubNA6io75G .mbr-section-title {
    line-height: 25px;
  }
}
.cid-ubNAoAEegD {
  padding-top: 0rem;
  padding-bottom: 1rem;
  background-color: #ffc092;
}
.cid-ubNAoAEegD .mbr-fallback-image.disabled {
  display: none;
}
.cid-ubNAoAEegD .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
@media (max-width: 992px) {
  .cid-ubNAoAEegD .container {
    padding: 0 50px;
  }
}
@media (max-width: 768px) {
  .cid-ubNAoAEegD .container {
    padding: 0 16px;
  }
}
.cid-ubNAoAEegD .row {
  justify-content: center;
}
.cid-ubNAoAEegD .title-wrapper .mbr-section-title {
  margin-bottom: 50px;
}
@media (max-width: 992px) {
  .cid-ubNAoAEegD .title-wrapper .mbr-section-title {
    margin-bottom: 32px;
  }
}
.cid-ubNAoAEegD .mbr-section-subtitle {
  margin-bottom: 32px;
  color: #000000;
}
.cid-ubNAoAEegD .panel-group .card {
  border-bottom: 1px solid #000000;
  border-radius: 0 !important;
}
.cid-ubNAoAEegD .panel-group .card:first-child {
  border-top: 1px solid #000000;
}
.cid-ubNAoAEegD .panel-group .card .card-header {
  padding: 24px 0;
  border: none;
  background-color: transparent;
}
@media (max-width: 992px) {
  .cid-ubNAoAEegD .panel-group .card .card-header {
    padding: 20px 0;
  }
}
.cid-ubNAoAEegD .panel-group .card .card-header .panel-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cid-ubNAoAEegD
  .panel-group
  .card
  .card-header
  .panel-title:not(.collapsed)
  .mbr-iconfont {
  transform: rotate(180deg);
}
.cid-ubNAoAEegD .panel-group .card .card-header .panel-title .panel-title-edit {
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .cid-ubNAoAEegD
    .panel-group
    .card
    .card-header
    .panel-title
    .panel-title-edit {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-ubNAoAEegD .panel-group .card .card-header .panel-title .mbr-iconfont {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  min-width: 32px;
  font-size: 16px;
  color: #000000;
  transition: all 0.3s ease-in-out;
  margin-left: 8px;
}
.cid-ubNAoAEegD .panel-group .card .panel-collapse .panel-body {
  padding-bottom: 24px;
}
@media (max-width: 992px) {
  .cid-ubNAoAEegD .panel-group .card .panel-collapse .panel-body {
    padding-bottom: 20px;
  }
}
.cid-ubNAoAEegD .panel-group .card .panel-collapse .panel-body .panel-text {
  margin-bottom: 0;
}
.cid-ubNAoAEegD .mbr-section-title {
  color: #000000;
  text-align: center;
}
.cid-ubNAoAEegD .panel-title {
  color: #000000;
}
.cid-ubNAoAEegD .panel-text {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubNAoAEegD .panel-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-ubNAoAEegD .panel-title-edit {
  text-align: left;
}
.cid-ubNCsF1uPu {
  padding-top: 2rem;
  padding-bottom: 1rem;
  background-color: #ffc092;
}
.cid-ubNCsF1uPu .mbr-section-subtitle {
  text-align: center;
}
.cid-ubNCsF1uPu .mbr-text {
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubNCsF1uPu .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-ubNAIEstU4 {
  padding-top: 0rem;
  padding-bottom: 1rem;
  background-color: #ffc092;
}
.cid-ubNAIEstU4 .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-ubNAIEstU4 .btn {
    width: 160px;
    font-size: 12px;
  }
}
.cid-ubPxudOAjM {
  padding-top: 2rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubPxudOAjM .mbr-fallback-image.disabled {
  display: none;
}
.cid-ubPxudOAjM .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-ubPxudOAjM .mbr-section-title {
  text-align: center;
}
@media (max-width: 768px) {
  .cid-ubPxudOAjM .mbr-section-title {
    line-height: 25px;
  }
}
.cid-ubPxjk7sWU {
  padding-top: 0rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubPxjk7sWU .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-ubPxjk7sWU .btn {
    width: 160px;
    font-size: 12px;
  }
}
.cid-ubPxjk7sWU .icons {
  height: 16px;
  width: 16px;
}
.cid-ubPxjk7sWU .valign-in-div {
  align: center;
  display: flex;
  justify-content: left;
  align-items: left;
}
@media (max-width: 768px) {
  .cid-ubPxjk7sWU .valign-in-div {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }
}
.cid-ubPxjk7sWU .less-spacing {
  padding-bottom: 0px;
}
.cid-ubPxjk7sWU .small-font {
  font-size: 16px;
  font-weight: 400px;
  color: #000000;
}
@media (max-width: 768px) {
  .cid-ubPxjk7sWU .small-font {
    font-size: 11px;
    text-align: center;
  }
}
.cid-ubPxjk7sWU .width-320 {
  width: 320px;
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .cid-ubPxjk7sWU .width-320 {
    text-align: center;
  }
}
.cid-ubPxjk7sWU .mbr-fallback-image.disabled {
  display: none;
}
.cid-ubPxjk7sWU .container {
  max-width: 1300px;
}
.cid-ubPxjk7sWU .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-ubPxjk7sWU .text-wrapper {
  max-width: 700px;
  margin: auto;
}
@media (min-width: 992px) {
  .cid-ubPxjk7sWU .text-wrapper {
    padding: 0 2rem;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .cid-ubPxjk7sWU .image-wrapper {
    margin-bottom: 0rem;
  }
}
.cid-ubPxjk7sWU .row {
  align-items: center;
}
@media (max-width: 991px) {
  .cid-ubPxjk7sWU .image-wrapper {
    padding: 1.5rem;
  }
}
.cid-ubPxjk7sWU .mbr-section-title {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPxjk7sWU .mbr-section-title {
    text-align: center;
  }
}
.cid-ubPxjk7sWU .mbr-text {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPxjk7sWU .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-ubPxjk7sWU .mbr-section-btn {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPxjk7sWU .mbr-section-btn {
    text-align: center;
    padding-top: 1px;
    padding-bottom: 30px;
  }
}
.cid-ubPxjk7sWU .mbr-section-subtitle {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPxjk7sWU .mbr-section-subtitle {
    text-align: center;
  }
}
.cid-ubPApEt2WB {
  padding-top: 0rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubPApEt2WB .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-ubPApEt2WB .btn {
    width: 160px;
    font-size: 12px;
  }
}
.cid-ubPApEt2WB .icons {
  height: 16px;
  width: 16px;
}
.cid-ubPApEt2WB .valign-in-div {
  align: center;
  display: flex;
  justify-content: left;
  align-items: left;
}
@media (max-width: 768px) {
  .cid-ubPApEt2WB .valign-in-div {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }
}
.cid-ubPApEt2WB .less-spacing {
  padding-bottom: 0px;
}
.cid-ubPApEt2WB .small-font {
  font-size: 16px;
  font-weight: 400px;
  color: #000000;
}
@media (max-width: 768px) {
  .cid-ubPApEt2WB .small-font {
    font-size: 11px;
    text-align: center;
  }
}
.cid-ubPApEt2WB .width-320 {
  width: 320px;
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .cid-ubPApEt2WB .width-320 {
    text-align: center;
  }
}
.cid-ubPApEt2WB .mbr-fallback-image.disabled {
  display: none;
}
.cid-ubPApEt2WB .container {
  max-width: 1300px;
}
.cid-ubPApEt2WB .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-ubPApEt2WB .text-wrapper {
  max-width: 700px;
  margin: auto;
}
@media (min-width: 992px) {
  .cid-ubPApEt2WB .text-wrapper {
    padding: 0 2rem;
    text-align: center;
  }
}
.cid-ubPApEt2WB .row {
  flex-direction: row-reverse;
}
@media (max-width: 991px) {
  .cid-ubPApEt2WB .image-wrapper {
    margin-bottom: 0rem;
  }
}
.cid-ubPApEt2WB .row {
  align-items: center;
}
@media (max-width: 991px) {
  .cid-ubPApEt2WB .image-wrapper {
    padding: 1.5rem;
  }
}
.cid-ubPApEt2WB .mbr-section-title {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPApEt2WB .mbr-section-title {
    text-align: center;
  }
}
.cid-ubPApEt2WB .mbr-text {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPApEt2WB .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-ubPApEt2WB .mbr-section-btn {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPApEt2WB .mbr-section-btn {
    text-align: center;
    padding-top: 1px;
    padding-bottom: 30px;
  }
}
.cid-ubPApEt2WB .mbr-section-subtitle {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPApEt2WB .mbr-section-subtitle {
    text-align: center;
  }
}
.cid-ubPAHG2oEL {
  padding-top: 0rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubPAHG2oEL .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-ubPAHG2oEL .btn {
    width: 160px;
    font-size: 12px;
  }
}
.cid-ubPAHG2oEL .icons {
  height: 16px;
  width: 16px;
}
.cid-ubPAHG2oEL .valign-in-div {
  align: center;
  display: flex;
  justify-content: left;
  align-items: left;
}
@media (max-width: 768px) {
  .cid-ubPAHG2oEL .valign-in-div {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }
}
.cid-ubPAHG2oEL .less-spacing {
  padding-bottom: 0px;
}
.cid-ubPAHG2oEL .small-font {
  font-size: 16px;
  font-weight: 400px;
  color: #000000;
}
@media (max-width: 768px) {
  .cid-ubPAHG2oEL .small-font {
    font-size: 11px;
    text-align: center;
  }
}
.cid-ubPAHG2oEL .width-320 {
  width: 320px;
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .cid-ubPAHG2oEL .width-320 {
    text-align: center;
  }
}
.cid-ubPAHG2oEL .mbr-fallback-image.disabled {
  display: none;
}
.cid-ubPAHG2oEL .container {
  max-width: 1300px;
}
.cid-ubPAHG2oEL .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-ubPAHG2oEL .text-wrapper {
  max-width: 700px;
  margin: auto;
}
@media (min-width: 992px) {
  .cid-ubPAHG2oEL .text-wrapper {
    padding: 0 2rem;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .cid-ubPAHG2oEL .image-wrapper {
    margin-bottom: 0rem;
  }
}
.cid-ubPAHG2oEL .row {
  align-items: center;
}
@media (max-width: 991px) {
  .cid-ubPAHG2oEL .image-wrapper {
    padding: 1.5rem;
  }
}
.cid-ubPAHG2oEL .mbr-section-title {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPAHG2oEL .mbr-section-title {
    text-align: center;
  }
}
.cid-ubPAHG2oEL .mbr-text {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPAHG2oEL .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-ubPAHG2oEL .mbr-section-btn {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPAHG2oEL .mbr-section-btn {
    text-align: center;
    padding-top: 1px;
    padding-bottom: 30px;
  }
}
.cid-ubPAHG2oEL .mbr-section-subtitle {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPAHG2oEL .mbr-section-subtitle {
    text-align: center;
  }
}
.cid-ubPARn9LRL {
  padding-top: 0rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubPARn9LRL .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-ubPARn9LRL .btn {
    width: 160px;
    font-size: 12px;
  }
}
.cid-ubPARn9LRL .icons {
  height: 16px;
  width: 16px;
}
.cid-ubPARn9LRL .valign-in-div {
  align: center;
  display: flex;
  justify-content: left;
  align-items: left;
}
@media (max-width: 768px) {
  .cid-ubPARn9LRL .valign-in-div {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }
}
.cid-ubPARn9LRL .less-spacing {
  padding-bottom: 0px;
}
.cid-ubPARn9LRL .small-font {
  font-size: 16px;
  font-weight: 400px;
  color: #000000;
}
@media (max-width: 768px) {
  .cid-ubPARn9LRL .small-font {
    font-size: 11px;
    text-align: center;
  }
}
.cid-ubPARn9LRL .width-320 {
  width: 320px;
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .cid-ubPARn9LRL .width-320 {
    text-align: center;
  }
}
.cid-ubPARn9LRL .mbr-fallback-image.disabled {
  display: none;
}
.cid-ubPARn9LRL .container {
  max-width: 1300px;
}
.cid-ubPARn9LRL .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-ubPARn9LRL .text-wrapper {
  max-width: 700px;
  margin: auto;
}
@media (min-width: 992px) {
  .cid-ubPARn9LRL .text-wrapper {
    padding: 0 2rem;
    text-align: center;
  }
}
.cid-ubPARn9LRL .row {
  flex-direction: row-reverse;
}
@media (max-width: 991px) {
  .cid-ubPARn9LRL .image-wrapper {
    margin-bottom: 0rem;
  }
}
.cid-ubPARn9LRL .row {
  align-items: center;
}
@media (max-width: 991px) {
  .cid-ubPARn9LRL .image-wrapper {
    padding: 1.5rem;
  }
}
.cid-ubPARn9LRL .mbr-section-title {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPARn9LRL .mbr-section-title {
    text-align: center;
  }
}
.cid-ubPARn9LRL .mbr-text {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPARn9LRL .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-ubPARn9LRL .mbr-section-btn {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPARn9LRL .mbr-section-btn {
    text-align: center;
    padding-top: 1px;
    padding-bottom: 30px;
  }
}
.cid-ubPARn9LRL .mbr-section-subtitle {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPARn9LRL .mbr-section-subtitle {
    text-align: center;
  }
}
.cid-ubPASyzsVm {
  padding-top: 0rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubPASyzsVm .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-ubPASyzsVm .btn {
    width: 160px;
    font-size: 12px;
  }
}
.cid-ubPASyzsVm .icons {
  height: 16px;
  width: 16px;
}
.cid-ubPASyzsVm .valign-in-div {
  align: center;
  display: flex;
  justify-content: left;
  align-items: left;
}
@media (max-width: 768px) {
  .cid-ubPASyzsVm .valign-in-div {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }
}
.cid-ubPASyzsVm .less-spacing {
  padding-bottom: 0px;
}
.cid-ubPASyzsVm .small-font {
  font-size: 16px;
  font-weight: 400px;
  color: #000000;
}
@media (max-width: 768px) {
  .cid-ubPASyzsVm .small-font {
    font-size: 11px;
    text-align: center;
  }
}
.cid-ubPASyzsVm .width-320 {
  width: 320px;
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .cid-ubPASyzsVm .width-320 {
    text-align: center;
  }
}
.cid-ubPASyzsVm .mbr-fallback-image.disabled {
  display: none;
}
.cid-ubPASyzsVm .container {
  max-width: 1300px;
}
.cid-ubPASyzsVm .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-ubPASyzsVm .text-wrapper {
  max-width: 700px;
  margin: auto;
}
@media (min-width: 992px) {
  .cid-ubPASyzsVm .text-wrapper {
    padding: 0 2rem;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .cid-ubPASyzsVm .image-wrapper {
    margin-bottom: 0rem;
  }
}
.cid-ubPASyzsVm .row {
  align-items: center;
}
@media (max-width: 991px) {
  .cid-ubPASyzsVm .image-wrapper {
    padding: 1.5rem;
  }
}
.cid-ubPASyzsVm .mbr-section-title {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPASyzsVm .mbr-section-title {
    text-align: center;
  }
}
.cid-ubPASyzsVm .mbr-text {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPASyzsVm .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-ubPASyzsVm .mbr-section-btn {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPASyzsVm .mbr-section-btn {
    text-align: center;
    padding-top: 1px;
    padding-bottom: 30px;
  }
}
.cid-ubPASyzsVm .mbr-section-subtitle {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPASyzsVm .mbr-section-subtitle {
    text-align: center;
  }
}
.cid-ubPAVwrJeW {
  padding-top: 0rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubPAVwrJeW .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-ubPAVwrJeW .btn {
    width: 160px;
    font-size: 12px;
  }
}
.cid-ubPAVwrJeW .icons {
  height: 16px;
  width: 16px;
}
.cid-ubPAVwrJeW .valign-in-div {
  align: center;
  display: flex;
  justify-content: left;
  align-items: left;
}
@media (max-width: 768px) {
  .cid-ubPAVwrJeW .valign-in-div {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }
}
.cid-ubPAVwrJeW .less-spacing {
  padding-bottom: 0px;
}
.cid-ubPAVwrJeW .small-font {
  font-size: 16px;
  font-weight: 400px;
  color: #000000;
}
@media (max-width: 768px) {
  .cid-ubPAVwrJeW .small-font {
    font-size: 11px;
    text-align: center;
  }
}
.cid-ubPAVwrJeW .width-320 {
  width: 320px;
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .cid-ubPAVwrJeW .width-320 {
    text-align: center;
  }
}
.cid-ubPAVwrJeW .mbr-fallback-image.disabled {
  display: none;
}
.cid-ubPAVwrJeW .container {
  max-width: 1300px;
}
.cid-ubPAVwrJeW .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-ubPAVwrJeW .text-wrapper {
  max-width: 700px;
  margin: auto;
}
@media (min-width: 992px) {
  .cid-ubPAVwrJeW .text-wrapper {
    padding: 0 2rem;
    text-align: center;
  }
}
.cid-ubPAVwrJeW .row {
  flex-direction: row-reverse;
}
@media (max-width: 991px) {
  .cid-ubPAVwrJeW .image-wrapper {
    margin-bottom: 0rem;
  }
}
.cid-ubPAVwrJeW .row {
  align-items: center;
}
@media (max-width: 991px) {
  .cid-ubPAVwrJeW .image-wrapper {
    padding: 1.5rem;
  }
}
.cid-ubPAVwrJeW .mbr-section-title {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPAVwrJeW .mbr-section-title {
    text-align: center;
  }
}
.cid-ubPAVwrJeW .mbr-text {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPAVwrJeW .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-ubPAVwrJeW .mbr-section-btn {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPAVwrJeW .mbr-section-btn {
    text-align: center;
    padding-top: 1px;
    padding-bottom: 30px;
  }
}
.cid-ubPAVwrJeW .mbr-section-subtitle {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPAVwrJeW .mbr-section-subtitle {
    text-align: center;
  }
}
.cid-ubPATwr1eI {
  padding-top: 0rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubPATwr1eI .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-ubPATwr1eI .btn {
    width: 160px;
    font-size: 12px;
  }
}
.cid-ubPATwr1eI .icons {
  height: 16px;
  width: 16px;
}
.cid-ubPATwr1eI .valign-in-div {
  align: center;
  display: flex;
  justify-content: left;
  align-items: left;
}
@media (max-width: 768px) {
  .cid-ubPATwr1eI .valign-in-div {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }
}
.cid-ubPATwr1eI .less-spacing {
  padding-bottom: 0px;
}
.cid-ubPATwr1eI .small-font {
  font-size: 16px;
  font-weight: 400px;
  color: #000000;
}
@media (max-width: 768px) {
  .cid-ubPATwr1eI .small-font {
    font-size: 11px;
    text-align: center;
  }
}
.cid-ubPATwr1eI .width-320 {
  width: 320px;
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .cid-ubPATwr1eI .width-320 {
    text-align: center;
  }
}
.cid-ubPATwr1eI .mbr-fallback-image.disabled {
  display: none;
}
.cid-ubPATwr1eI .container {
  max-width: 1300px;
}
.cid-ubPATwr1eI .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-ubPATwr1eI .text-wrapper {
  max-width: 700px;
  margin: auto;
}
@media (min-width: 992px) {
  .cid-ubPATwr1eI .text-wrapper {
    padding: 0 2rem;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .cid-ubPATwr1eI .image-wrapper {
    margin-bottom: 0rem;
  }
}
.cid-ubPATwr1eI .row {
  align-items: center;
}
@media (max-width: 991px) {
  .cid-ubPATwr1eI .image-wrapper {
    padding: 1.5rem;
  }
}
.cid-ubPATwr1eI .mbr-section-title {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPATwr1eI .mbr-section-title {
    text-align: center;
  }
}
.cid-ubPATwr1eI .mbr-text {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPATwr1eI .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-ubPATwr1eI .mbr-section-btn {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPATwr1eI .mbr-section-btn {
    text-align: center;
    padding-top: 1px;
    padding-bottom: 30px;
  }
}
.cid-ubPATwr1eI .mbr-section-subtitle {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPATwr1eI .mbr-section-subtitle {
    text-align: center;
  }
}
.cid-ubPAWefAOp {
  padding-top: 0rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ubPAWefAOp .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-ubPAWefAOp .btn {
    width: 160px;
    font-size: 12px;
  }
}
.cid-ubPAWefAOp .icons {
  height: 16px;
  width: 16px;
}
.cid-ubPAWefAOp .valign-in-div {
  align: center;
  display: flex;
  justify-content: left;
  align-items: left;
}
@media (max-width: 768px) {
  .cid-ubPAWefAOp .valign-in-div {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }
}
.cid-ubPAWefAOp .less-spacing {
  padding-bottom: 0px;
}
.cid-ubPAWefAOp .small-font {
  font-size: 16px;
  font-weight: 400px;
  color: #000000;
}
@media (max-width: 768px) {
  .cid-ubPAWefAOp .small-font {
    font-size: 11px;
    text-align: center;
  }
}
.cid-ubPAWefAOp .width-320 {
  width: 320px;
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .cid-ubPAWefAOp .width-320 {
    text-align: center;
  }
}
.cid-ubPAWefAOp .mbr-fallback-image.disabled {
  display: none;
}
.cid-ubPAWefAOp .container {
  max-width: 1300px;
}
.cid-ubPAWefAOp .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-ubPAWefAOp .text-wrapper {
  max-width: 700px;
  margin: auto;
}
@media (min-width: 992px) {
  .cid-ubPAWefAOp .text-wrapper {
    padding: 0 2rem;
    text-align: center;
  }
}
.cid-ubPAWefAOp .row {
  flex-direction: row-reverse;
}
@media (max-width: 991px) {
  .cid-ubPAWefAOp .image-wrapper {
    margin-bottom: 0rem;
  }
}
.cid-ubPAWefAOp .row {
  align-items: center;
}
@media (max-width: 991px) {
  .cid-ubPAWefAOp .image-wrapper {
    padding: 1.5rem;
  }
}
.cid-ubPAWefAOp .mbr-section-title {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPAWefAOp .mbr-section-title {
    text-align: center;
  }
}
.cid-ubPAWefAOp .mbr-text {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPAWefAOp .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-ubPAWefAOp .mbr-section-btn {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPAWefAOp .mbr-section-btn {
    text-align: center;
    padding-top: 1px;
    padding-bottom: 30px;
  }
}
.cid-ubPAWefAOp .mbr-section-subtitle {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-ubPAWefAOp .mbr-section-subtitle {
    text-align: center;
  }
}
.cid-ubPxnPzYaM {
  padding-top: 0rem;
  padding-bottom: 1rem;
  background-color: #ffc092;
}
.cid-ubPxnPzYaM .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-ubPxnPzYaM .btn {
    width: 160px;
    font-size: 12px;
  }
}
.cid-uchIgqJSog {
  padding-top: 2rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-uchIgqJSog .mbr-fallback-image.disabled {
  display: none;
}
.cid-uchIgqJSog .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-uchIgqJSog .mbr-section-title {
  text-align: center;
}
@media (max-width: 768px) {
  .cid-uchIgqJSog .mbr-section-title {
    line-height: 25px;
  }
}
.cid-uchImVODD0 {
  padding-top: 0rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-uchImVODD0 .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-uchImVODD0 .btn {
    width: 160px;
    font-size: 12px;
  }
}
.cid-uchImVODD0 .icons {
  height: 16px;
  width: 16px;
}
.cid-uchImVODD0 .valign-in-div {
  align: center;
  display: flex;
  justify-content: left;
  align-items: left;
}
@media (max-width: 768px) {
  .cid-uchImVODD0 .valign-in-div {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }
}
.cid-uchImVODD0 .less-spacing {
  padding-bottom: 0px;
}
.cid-uchImVODD0 .small-font {
  font-size: 16px;
  font-weight: 400px;
  color: #000000;
}
@media (max-width: 768px) {
  .cid-uchImVODD0 .small-font {
    font-size: 11px;
    text-align: center;
  }
}
.cid-uchImVODD0 .width-320 {
  width: 320px;
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .cid-uchImVODD0 .width-320 {
    text-align: center;
  }
}
.cid-uchImVODD0 .mbr-fallback-image.disabled {
  display: none;
}
.cid-uchImVODD0 .container {
  max-width: 1300px;
}
.cid-uchImVODD0 .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-uchImVODD0 .text-wrapper {
  max-width: 700px;
  margin: auto;
}
@media (min-width: 992px) {
  .cid-uchImVODD0 .text-wrapper {
    padding: 0 2rem;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .cid-uchImVODD0 .image-wrapper {
    margin-bottom: 0rem;
  }
}
.cid-uchImVODD0 .row {
  align-items: center;
}
@media (max-width: 991px) {
  .cid-uchImVODD0 .image-wrapper {
    padding: 1.5rem;
  }
}
.cid-uchImVODD0 .mbr-section-title {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-uchImVODD0 .mbr-section-title {
    text-align: center;
  }
}
.cid-uchImVODD0 .mbr-text {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-uchImVODD0 .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-uchImVODD0 .mbr-section-btn {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-uchImVODD0 .mbr-section-btn {
    text-align: center;
    padding-top: 1px;
    padding-bottom: 30px;
  }
}
.cid-uchImVODD0 .mbr-section-subtitle {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-uchImVODD0 .mbr-section-subtitle {
    text-align: center;
  }
}
.cid-uchIyhd3cv {
  padding-top: 0rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-uchIyhd3cv .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-uchIyhd3cv .btn {
    width: 160px;
    font-size: 12px;
  }
}
.cid-uchIyhd3cv .icons {
  height: 16px;
  width: 16px;
}
.cid-uchIyhd3cv .valign-in-div {
  align: center;
  display: flex;
  justify-content: left;
  align-items: left;
}
@media (max-width: 768px) {
  .cid-uchIyhd3cv .valign-in-div {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }
}
.cid-uchIyhd3cv .less-spacing {
  padding-bottom: 0px;
}
.cid-uchIyhd3cv .small-font {
  font-size: 16px;
  font-weight: 400px;
  color: #000000;
}
@media (max-width: 768px) {
  .cid-uchIyhd3cv .small-font {
    font-size: 11px;
    text-align: center;
  }
}
.cid-uchIyhd3cv .width-320 {
  width: 320px;
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .cid-uchIyhd3cv .width-320 {
    text-align: center;
  }
}
.cid-uchIyhd3cv .mbr-fallback-image.disabled {
  display: none;
}
.cid-uchIyhd3cv .container {
  max-width: 1300px;
}
.cid-uchIyhd3cv .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-uchIyhd3cv .text-wrapper {
  max-width: 700px;
  margin: auto;
}
@media (min-width: 992px) {
  .cid-uchIyhd3cv .text-wrapper {
    padding: 0 2rem;
    text-align: center;
  }
}
.cid-uchIyhd3cv,
.reverse .row {
  flex-direction: row-reverse;
}
@media (max-width: 991px) {
  .cid-uchIyhd3cv .image-wrapper {
    margin-bottom: 0rem;
  }
}
.cid-uchIyhd3cv .row {
  align-items: center;
}
@media (max-width: 991px) {
  .cid-uchIyhd3cv .image-wrapper {
    padding: 1.5rem;
  }
}
.cid-uchIyhd3cv .mbr-section-title {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-uchIyhd3cv .mbr-section-title {
    text-align: center;
  }
}
.cid-uchIyhd3cv .mbr-text {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-uchIyhd3cv .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-uchIyhd3cv .mbr-section-btn {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-uchIyhd3cv .mbr-section-btn {
    text-align: center;
    padding-top: 1px;
    padding-bottom: 30px;
  }
}
.cid-uchIyhd3cv .mbr-section-subtitle {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-uchIyhd3cv .mbr-section-subtitle {
    text-align: center;
  }
}
.cid-uchMf5bCD3 {
  padding-top: 0rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-uchMf5bCD3 .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-uchMf5bCD3 .btn {
    width: 160px;
    font-size: 12px;
  }
}
.tour-disabled .btn-disabled,
.tour-disabled .btn-disabled:active,
.cid-uchMf5bCD3 .btn-disabled,
.cid-uchMf5bCD3 .btn-disabled:active {
  background-color: #e9975b !important;
  color: #d37530;
  cursor: not-allowed;
}
.tour-disabled .btn-disabled:hover,
.tour-disabled .btn-disabled:focus,
.tour-disabled .btn-disabled.focus,
.tour-disabled .btn-disabled.active,
.cid-uchMf5bCD3 .btn-disabled:hover,
.cid-uchMf5bCD3 .btn-disabled:focus,
.cid-uchMf5bCD3 .btn-disabled.focus,
.cid-uchMf5bCD3 .btn-disabled.active {
  background-color: #e9975b !important;
  color: #d37530;
  cursor: not-allowed;
}
.cid-uchMf5bCD3 .icons {
  height: 16px;
  width: 16px;
}
.cid-uchMf5bCD3 .valign-in-div {
  align: center;
  display: flex;
  justify-content: left;
  align-items: left;
}
@media (max-width: 768px) {
  .cid-uchMf5bCD3 .valign-in-div {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }
}
.cid-uchMf5bCD3 .less-spacing {
  padding-bottom: 0px;
}
.cid-uchMf5bCD3 .small-font {
  font-size: 16px;
  font-weight: 400px;
  color: #000000;
}
@media (max-width: 768px) {
  .cid-uchMf5bCD3 .small-font {
    font-size: 11px;
    text-align: center;
  }
}
.cid-uchMf5bCD3 .width-320 {
  width: 320px;
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .cid-uchMf5bCD3 .width-320 {
    text-align: center;
  }
}
.cid-uchMf5bCD3 .mbr-fallback-image.disabled {
  display: none;
}
.cid-uchMf5bCD3 .container {
  max-width: 1300px;
}
.cid-uchMf5bCD3 .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-uchMf5bCD3 .text-wrapper {
  max-width: 700px;
  margin: auto;
}
@media (min-width: 992px) {
  .cid-uchMf5bCD3 .text-wrapper {
    padding: 0 2rem;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .cid-uchMf5bCD3 .image-wrapper {
    margin-bottom: 0rem;
  }
}
.cid-uchMf5bCD3 .row {
  align-items: center;
}
@media (max-width: 991px) {
  .cid-uchMf5bCD3 .image-wrapper {
    padding: 1.5rem;
  }
}
.cid-uchMf5bCD3 .mbr-section-title {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-uchMf5bCD3 .mbr-section-title {
    text-align: center;
  }
}
.cid-uchMf5bCD3 .mbr-text {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-uchMf5bCD3 .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-uchMf5bCD3 .mbr-section-btn {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-uchMf5bCD3 .mbr-section-btn {
    text-align: center;
    padding-top: 1px;
    padding-bottom: 30px;
  }
}
.cid-uchMf5bCD3 .mbr-section-subtitle {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-uchMf5bCD3 .mbr-section-subtitle {
    text-align: center;
  }
}
.cid-uchWOyxo1d {
  padding-top: 0rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-uchWOyxo1d .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-uchWOyxo1d .btn {
    width: 160px;
    font-size: 12px;
  }
}
.cid-uchWOyxo1d .btn-disabled,
.cid-uchWOyxo1d .btn-disabled:active {
  background-color: #e9975b !important;
  color: #d37530;
  cursor: not-allowed;
}
.cid-uchWOyxo1d .btn-disabled:hover,
.cid-uchWOyxo1d .btn-disabled:focus,
.cid-uchWOyxo1d .btn-disabled.focus,
.cid-uchWOyxo1d .btn-disabled.active {
  background-color: #e9975b !important;
  color: #d37530;
  cursor: not-allowed;
}
.cid-uchWOyxo1d .icons {
  height: 16px;
  width: 16px;
}
.cid-uchWOyxo1d .valign-in-div {
  align: center;
  display: flex;
  justify-content: left;
  align-items: left;
}
@media (max-width: 768px) {
  .cid-uchWOyxo1d .valign-in-div {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }
}
.cid-uchWOyxo1d .less-spacing {
  padding-bottom: 0px;
}
.cid-uchWOyxo1d .small-font {
  font-size: 16px;
  font-weight: 400px;
  color: #000000;
}
@media (max-width: 768px) {
  .cid-uchWOyxo1d .small-font {
    font-size: 11px;
    text-align: center;
  }
}
.cid-uchWOyxo1d .width-320 {
  width: 320px;
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .cid-uchWOyxo1d .width-320 {
    text-align: center;
  }
}
.cid-uchWOyxo1d .mbr-fallback-image.disabled {
  display: none;
}
.cid-uchWOyxo1d .container {
  max-width: 1300px;
}
.cid-uchWOyxo1d .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-uchWOyxo1d .text-wrapper {
  max-width: 700px;
  margin: auto;
}
@media (min-width: 992px) {
  .cid-uchWOyxo1d .text-wrapper {
    padding: 0 2rem;
    text-align: center;
  }
}
.cid-uchWOyxo1d .row {
  flex-direction: row-reverse;
}
@media (max-width: 991px) {
  .cid-uchWOyxo1d .image-wrapper {
    margin-bottom: 0rem;
  }
}
.cid-uchWOyxo1d .row {
  align-items: center;
}
@media (max-width: 991px) {
  .cid-uchWOyxo1d .image-wrapper {
    padding: 1.5rem;
  }
}
.cid-uchWOyxo1d .mbr-section-title {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-uchWOyxo1d .mbr-section-title {
    text-align: center;
  }
}
.cid-uchWOyxo1d .mbr-text {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-uchWOyxo1d .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
.cid-uchWOyxo1d .mbr-section-btn {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-uchWOyxo1d .mbr-section-btn {
    text-align: center;
    padding-top: 1px;
    padding-bottom: 30px;
  }
}
.cid-uchWOyxo1d .mbr-section-subtitle {
  color: #000000;
  text-align: left;
}
@media (max-width: 768px) {
  .cid-uchWOyxo1d .mbr-section-subtitle {
    text-align: center;
  }
}
.cid-uchIzN1XSR {
  padding-top: 0rem;
  padding-bottom: 1rem;
  background-color: #ffc092;
}
.cid-uchIzN1XSR .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-uchIzN1XSR .btn {
    width: 160px;
    font-size: 12px;
  }
}
.cid-ucjm3nUkNr {
  padding-top: 2rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-ucjm3nUkNr .mbr-fallback-image.disabled {
  display: none;
}
.cid-ucjm3nUkNr .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-ucjm3nUkNr .mbr-section-title {
  text-align: center;
}
@media (max-width: 768px) {
  .cid-ucjm3nUkNr .mbr-section-title {
    line-height: 25px;
  }
}
.cid-uco6xtWeNs {
  padding-top: 0rem;
  padding-bottom: 0rem;
  background-color: #ffc092;
}
.cid-uco6xtWeNs .mbr-section-subtitle {
  text-align: center;
}
.cid-uco6xtWeNs .mbr-text {
  text-align: center;
}
@media (max-width: 768px) {
  .cid-uco6xtWeNs .mbr-text {
    text-align: justify;
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .cid-uco6xtWeNs .padding-less {
    padding-left: 15px;
    text-align: left;
  }
}
.cid-ucjkK8xmy4 {
  padding-top: 0px;
  padding-bottom: 15px;
  background-color: #ffc092;
}
.cid-ucjkK8xmy4 .container {
  width: 100% !important;
}
.cid-ucjkK8xmy4 img {
  width: 303px;
  height: 190px;
  object-fit: cover;
}
.cid-ucjkK8xmy4 .row {
  justify-content: center;
}
.cid-ucjkK8xmy4 .item {
  padding: 0.5rem;
}
@media (max-width: 767px) {
  .cid-ucjkK8xmy4 .item {
    padding: 0.5rem;
  }
  .cid-ucjkK8xmy4 .row {
    padding: 0 0.5rem;
  }
  .cid-ucjkK8xmy4 .big-on {
    display: none;
  }
  .cid-ucjkK8xmy4 .small-on {
    display: flex;
  }
}
.cid-ucjkK8xmy4 .btn {
  border-radius: 0;
}
.cid-ucjkK8xmy4 .btn .mbr-iconfont {
  font-size: 1rem !important;
  margin: 0 0.5rem;
}
.cid-ucjkK8xmy4 .gallery-thumbs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cid-ucjkK8xmy4 .gallery-thumbs img {
  max-width: 300px;
  height: 300px;
  width: 300px;
  margin: 10px;
  border-radius: 10px;
}
.cid-ucjm4VrLHk {
  padding-top: 0rem;
  padding-bottom: 1rem;
  background-color: #ffc092;
}
.cid-ucjm4VrLHk .btn {
  width: 200px;
}
@media (max-width: 991px) {
  .cid-ucjm4VrLHk .btn {
    width: 160px;
    font-size: 12px;
  }
}

.pb-8 {
  padding-bottom: 4px;
}
.pt-8 {
  padding-top: 4px;
}
