.p-deposit {
  .modal-dialog {
    margin-top: 2.5rem;
    .modal-header {
      border-color: $secondary-color-500;
    }
    .modal-content {
      background: $black;
      h1 {
        margin: 0;
      }
      hr {
        margin-left: -1rem;
        color: $secondary-color-500;
        margin-right: -1rem;
      }
      #ideal_bank {
      }
      .form-control {
        // appearance: auto;
        //@extend .form-select;
        //@extend .form-select-sm;
      }
      .text-danger {
        color: $primary-color !important;
      }
      #ideal_bank_save {
        padding: 0.25rem 1rem;
      }
    }
    .hamburger {
      width: 25px;
      height: 25px;
      &:hover {
        &::after,
        &::before,
        span {
          background-color: $primary-color;
        }
      }
    }
  }
  .form-deposit {
    &-row {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 8rem;
      margin-bottom: 2rem;
      @media screen and (max-width: 500px) {
        display: block;
        margin-bottom: 1rem;
        button {
          margin: 0 auto;
        }
      }
    }
    &-column {
      text-align: center;
      label {
        margin-bottom: 0.5rem;
      }
      .form-control {
        width: 220px;
        appearance: auto;
        font-size: 0.9rem;
        padding: 0.6rem 1rem;
        @media screen and (max-width: 500px) {
          margin: 0 auto;
        }
      }
      @media screen and (max-width: 500px) {
        margin-bottom: 2rem;
      }
    }
  }
}
