.custom-page {
  min-height: 75vh;
  h1 {
    font-weight: bold;
    font-size: 1.5rem;
    color: #212529 !important;
  }
  p {
    text-align: left;
  }
}
