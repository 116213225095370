.lh-12 {
  line-height: 0.75rem;
}

.lh-20 {
  line-height: 1.25rem;
}

.fs-8 {
  font-size: 0.6rem;
}

.main-title {
  font-size: 2rem;
  letter-spacing: -0.1px;
  font-weight: 900;
  background: -webkit-linear-gradient($primary-color-100, $primary-color-500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 2px $secondary-color;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-transform: uppercase;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-dark {
  color: #010a0e;
}
.text-bold {
  font-weight: bold !important;
}
.title-of-page {
  font-size: 1.5rem;
  font-weight: bold;
  color: #010a0e;
}
.text-justify {
  text-align: justify;
}

.fs-16 {
  font-size: 1rem;
}
.fs-20 {
  font-size: 1.25rem;
}
