iframe {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  border-radius: 6px;
  z-index: 3;
}

.casino-game-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
}

.casino-game-container {
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: 0 0 24px 8px rgb(0 0 0 / 50%);
  border-radius: 6px;
  overflow: hidden;
}

.casino-game {
  margin-top: 32px;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.fullscreen {
  background: #000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 0;
  max-width: none !important;
  min-height: 0;
  max-height: none !important;
  margin: 0;
  z-index: 1030;
  .casino-game-container {
    height: 96%;
  }
}

.casino-fullscreen-icon.fa-expand-arrows {
  font-size: 28px;
  color: #fff;
  &:hover,
  &:active {
    color: #00a0ff;
  }
}

.casino-fullscreen-icon.fa-times {
  font-size: 38px;
  &:hover,
  &:active {
    color: #ff5454;
  }
}

.fullscreen .casino-game-iframe {
  aspect-ratio: 1/1;
}

.game-header,
.game-footer {
  height: unset;
  background: #090909;
  justify-content: space-between;
  display: flex;
  flex: 1;
  i {
    cursor: pointer;
  }

  svg {
    padding: 0.1em;
  }

  .game-footer-player-time {
    font-size: 0.65em;
    font-weight: 200;
    color: #fff;
    letter-spacing: 0.05em;

    .gametime {
      vertical-align: middle;
    }
  }
}

.btx {
  .casino-game {
    max-width: 75vw;
  }

  .casino-game.full-screen {
    .casino-game-iframe {
      height: calc(100vh - 10vh);
    }
  }

  .casino-game-iframe {
    aspect-ratio: 128/65;
  }
}

.fag {
  .casino-game {
    max-height: 90vh;
  }
}

.pgs,
.smso {
  .casino-game {
    max-width: 65vw;
    margin-top: 10px;
    &.fullscreen {
      margin-top: 0;
    }
  }

  .casino-game.full-screen {
    .casino-game-iframe {
      height: calc(100vh - 10vh);
    }
  }

  .casino-game-iframe {
    aspect-ratio: 16/9;
  }
}

.game-with-sidebar {
  column-gap: 1rem;
  .toggle-chat {
    display: none;
    margin-right: 16px;
    i {
      font-size: 28px;
      color: #fff;
    }
    &.show {
      display: inline;
    }
  }
  .game-chat-sidebar {
    width: 20vw;
    display: none;
    background-color: #fff;
    box-shadow: 0 0 24px 8px rgb(0 0 0 / 50%);
    margin-top: 32px;
    &.show {
      display: block;
    }
    iframe {
      position: initial;
      width: 100%;
      height: calc(100% - 64px);
    }
    &__header {
      padding: 8px 12px;
      display: flex;
      align-items: center;
      height: 64px;
      justify-content: flex-end;
      .chat-service__btn {
        border: none;
        background: transparent;
        font-size: 32px;
        color: #000;
        cursor: pointer;
        padding: 0;
      }
    }
    &.fullscreen-mode {
      position: fixed;
      z-index: 9999;
      right: 0.9%;
      bottom: 10%;
      height: 45%;
      width: 34%;
    }
  }
}

@media (min-width: 1440px) {
  .btx {
    .casino-game {
      max-width: 84vw;
    }

    .casino-game-iframe {
      aspect-ratio: 16/10;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .btx {
    .casino-game {
      max-width: 1150px;
    }

    .casino-game-iframe {
      aspect-ratio: 16/10;
    }
  }
  .pgs,
  .smso {
    .casino-game {
      max-width: 1150px;
    }

    .casino-game-iframe {
      aspect-ratio: 16/9;
    }
  }
  .game-with-sidebar {
    column-gap: 1rem;
    .casino-game {
      width: 70vw;
    }
    .game-chat-sidebar {
      width: 28vw;
      margin-top: 32px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .btx {
    .casino-game {
      max-width: 640px;
    }

    .casino-game-iframe {
      aspect-ratio: 4/3;
    }
  }

  .fag {
    .casino-game {
      max-width: 100% !important;
      max-height: 500px;
    }
  }

  .pgs,
  .smso {
    .casino-game {
      max-width: 640px;
    }

    .casino-game-iframe {
      aspect-ratio: 16/9;
    }
  }
  .game-with-sidebar {
    column-gap: 1rem;
    .casino-game {
      width: 100%;
    }
    .game-chat-sidebar {
      width: 35%;
      height: 68%;
      margin-top: 32px;
      position: absolute;
      right: 0.9%;
      bottom: 17.1%;
      z-index: 10;
      &__header {
        padding: 4px 8px;
        height: 36px;
        .chat-service__btn {
          font-size: 24px;
        }
      }
    }
  }
}

@media (min-width: 480px) and (orientation: portrait) {
  .btx {
    .casino-game {
      max-width: 90vw;

      .casino-game-iframe {
        height: calc(100vh - 30vh);
        aspect-ratio: 9/16;
      }
    }
    .casino-game.fullscreen {
      max-width: 100%;

      .casino-game-iframe {
        height: calc(100vh - 10vh);
        aspect-ratio: 9/16;
      }
    }
  }
  .fag {
    .casino-game {
      max-height: 67vh;
    }
  }
  .pgs,
  .smso {
    .casino-game {
      max-width: 90vw;

      .casino-game-iframe {
        height: calc(100vh - 30vh);
        aspect-ratio: 9/16;
      }
    }
    .casino-game.fullscreen {
      max-width: 100%;

      .casino-game-iframe {
        height: calc(100vh - 10vh);
        aspect-ratio: 9/16;
      }
    }
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .btx {
    .casino-game {
      max-width: 90vw;
    }

    .casino-game-iframe {
      aspect-ratio: 2/3;
    }
  }
  .fag {
    .casino-game {
      max-width: 100% !important;
      max-height: 360px;
    }
  }
  .pgs,
  .smso {
    .casino-game {
      max-width: 90vw;
    }

    .casino-game-iframe {
      aspect-ratio: 3/2;
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .btx {
    .casino-game {
      max-width: 100%;
    }

    .casino-game-iframe {
      height: calc(100vh - 10vh);
      aspect-ratio: unset;
    }
  }
  .fag {
    .casino-game {
      max-height: 670px;
    }
  }
  .pgs,
  .smso {
    .casino-game {
      max-width: 100%;
    }

    .casino-game-iframe {
      height: calc(100vh - 10vh);
      aspect-ratio: unset;
    }
  }
}

@media screen and (max-width: 1024px) {
  .game-with-sidebar {
    .game-chat-sidebar {
      width: 35%;
      height: 68%;
      margin-top: 54px;
      position: absolute;
      right: 0.9%;
      bottom: 17.1%;
      z-index: 10;
      &__header {
        padding: 4px 8px;
        height: 36px;
        .chat-service__btn {
          font-size: 24px;
        }
      }
      &.fullscreen-mode {
        position: fixed;
        z-index: 10;
        right: auto;
        top: 0;
        left: 0;
        bottom: auto;
        height: 45%;
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .game-with-sidebar {
    .game-chat-sidebar {
      bottom: 12.1%;
      width: 60%;
    }
  }
}
@media screen and (max-width: 576px) {
  .game-with-sidebar {
    .game-chat-sidebar {
      width: 100%;
      min-height: 300px;
      height: 50%;
      right: auto;
      left: 0;
      top: 24px;
      bottom: auto;
    }
  }
}
