.my-account {
  &-form {
    .d-flex {
      @media screen and (max-width: 576px) {
        flex-wrap: wrap;
      }
    }
    &__field {
      flex: 0 0 45%;
      max-width: 45%;
      @media screen and (max-width: 576px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .reqiured {
        color: red;
      }
    }
  }
}
