.bg-black {
    background: $background-default;
}

.bg-dark-gray {
    background: $secondary-color;
}

.bg-black-transparent {
    background: rgb($secondary-color-900, 0.7);
}

.bg-default {
    background: $background-default;
}

.border-primary-blue {
    border-color: $secondary-color;
}   

.bg-success {
	background-color: $primary-color-100;
}

.futu-blue {
    color: $primary-color;
}

a.futu-blue {
    &:hover {
        color: $primary-color;
        text-decoration: underline;
    }
}

.text-aqua {
	color: $primary-color;
	&:hover { 
		color: $primary-color;
		text-decoration: underline; 
	}
}

.text-futu-primary, .login-form a, .btn-link, .login-form a:hover, .btn-link:hover {
    color: $primary-color !important;
}

.bg-dark {
    // background-color: #1a132b;
    // border-bottom: 0.063rem #301e32 solid;
    // border-top: 0.063rem #140b29 solid;
    --bs-bg-opacity: 0;
    background-color: $secondary-color-900 !important; // TODO: solved this without important
}

.bg-darker {
    background-color: $secondary-color-850;
}

.bg-darker-transparent {
    background: rgba(18, 20, 30, 0.9);
}

.bg-casino-1,
.bg-casino-2 {
     background-color: $peach;
    // background-image: url('/assets/site/images/bg-casino-dark.jpg');
    // background-repeat: repeat;
    // background-attachment: fixed;
    // background-size: 100%;
}
