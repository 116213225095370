.login {
  &-container {
    h2 {
      font-size: 1.45rem;
      text-align: center;
      font-weight: 500;
      color: #00a0ff;
    }
  }
  &-form {
    .form-label {
      font-size: 1rem;
    }
  }
  &-subtitle {
    font-size: 1rem;
  }
  &-btns-wrap {
    @media screen and (max-width: 576px) {
      flex-wrap: wrap;
      justify-content: center;
      .forgot-password {
        margin-top: 16px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
