.p-profile {
  @include breakpoint("lg-max") {
    .c-header {
      position: sticky;
      top: 0px;
    }
    &.o-transform {
      margin-top: 0;
      .c-header {
        top: 0px;
        transition: none;
        transform: none;
      }
    }
  }

  .referral-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 8px;
    & > div {
      flex: 0 0 35px;
      aspect-ratio: 1;
      cursor: pointer;
      font-size: 28px;
      a {
        color: $white;
      }
      &:hover {
        a {
          color: $blue;
        }
      }
    }
  }

  .form-group label,
  .form-label {
    margin-bottom: 0.5rem;
    color: #010a0e;
  }
  &-game-settings {
  }
  .form-check {
    min-height: 2.4em;
    .form-check-input {
      width: 2em;
      height: 2em;
    }
    .form-check-label {
      margin-left: 8px;
      margin-top: 5px;
    }
  }
  .table {
    border-color: #010a0e;
  }
}
