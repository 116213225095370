.pb-10 {
    padding-bottom: 2.5rem;
}

.py-15 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.ml-2 {
    margin-left: .5rem;
}

.mr-2 {
    margin-right: .5rem;
}

.mb-10 {
    margin-bottom: 2.5rem;
}

.my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}


.start-85 {
    left: 85%; 
}
