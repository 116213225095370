.register {
  &-steps {
    .card-body {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .c-process {
      margin: 0 auto;
      @media screen and (max-width: 500px) {
        width: 100%;
      }
      &-line {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 4px;
        @media screen and (max-width: 345px) {
          padding-left: 4px;
          padding-right: 4px;
        }
        @media screen and (max-width: 345px) {
          padding-left: 1px;
          padding-right: 1px;
        }
      }
      &-circle {
        width: 50px;
        height: 50px;
        font-size: 1.5rem;
        font-weight: bold;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f5811e;
        border-radius: 150%;
        &.non-active {
          background-color: #c9a78c;
          color: #ffdcc4;
        }
        @media screen and (max-width: 500px) {
          width: 40px;
          height: 40px;
          font-size: 1.25rem;
        }
        @media screen and (max-width: 345px) {
          width: 36px;
          height: 36px;
        }
      }
    }
  }
  &-page {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    .form-label {
      color: #010a0e;
    }
    .text-left {
      text-align: left;
    }
    .alert-danger {
      color: #fff !important;
      .text-danger {
        color: #fff !important;
        text-align: left;
      }
    }
  }
  &-form-wrap {
    .text-start {
      color: #010a0e;
    }
    .form-group {
      label {
        color: #010a0e;
        font-size: 1rem;
      }
      .invalid-feedback {
        font-size: 1rem;
        margin-bottom: 8px;
        .form-error-icon {
          padding-left: 0;
          color: #dc3545;
        }
      }
    }
  }
  &-footer-text {
    font-size: 0.8rem;
    line-height: 125%;
    color: #010a0e;
  }
  &-form-step {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    width: 100%;
    .form-group {
      label {
        font-size: 1rem;
        color: #010a0e;
      }
    }
    .form-text {
      font-size: 1rem;
      color: #010a0e !important;
      margin: 1rem 0;
      display: block;
    }
  }
}

.email-form {
  .btn-resend {
    border: none;
    box-shadow: none;
    color: #010a0e !important;
    text-decoration: underline;
    &:hover,
    &:focus,
    &:active {
      background-color: transparent !important;
      color: #010a0e !important;
    }
  }
  .send-wrap {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8px;
  }
  .resend-wrap {
    margin: 0 auto;
  }
}
