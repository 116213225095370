@include breakpoint('lg') {
    .sticky-top-side {
      position: sticky;
      top: 6rem;
      z-index: 10;
    }
}


.circle-flag {
    clip-path: circle(1.125rem);
    width: 3.75rem;
    height: 2.5rem;
  }
  
  
  .logo-partner {
    padding: 0.175rem;
  }
  
  .gamble-tools {
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  
  .text-stroke {
    text-shadow: -0.063rem -0.063rem 0 #000, 0.063rem -0.063rem 0 #000, -0.063rem 0.063rem 0 #000, 0.063rem 0.063rem 0 #000;
  }
  
  .o-banner {
    border: 0.063rem solid #ffffff;
    object, img {
      object-fit: unset;
      object-position: center;
    }
  }
  
  .idin-logo-onboarding {
    padding: 1em;
    float: right;
    max-width: 10em;
  
    @media (max-width: 768px) {
      max-width: 5em;
    }
  }
  
  .rounded-fvi {
    border-radius: 0.125rem !important;
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
  }
  
  .mobile-login {
    background-color: $background-default;
    top: 1.5rem;
    padding: .5rem 0;
  }
  
  @keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }
  
  .gradient-purple-movin {

    --size: 250px;
    --speed: 50s;
    --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);

    width: var(--size);
    height: var(--size);
    filter: blur(calc(var(--size) / 5));
    background-image: linear-gradient(hsl(158, 82, 57, 85%), hsl(252, 82, 57));
    animation: var(--speed) var(--easing) alternate infinite;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }