.left-sidebar {
  background-color: #f5811e;
  .card-body {
    h2 {
      font-weight: bold;
    }
  }
  .card-list {
    .card-link {
      &::after {
        border-color: #fff;
        width: 0;
      }

      &:hover,
      &:focus,
      &:active {
        color: #fff;
        font-weight: 500;
        &::after {
          width: 100%;
        }
      }
    }
    .has-children {
      .card-link__childs {
        transform: translateY(-100%);
        transition: all 0.25s;
        opacity: 0;
        height: 0;
        z-index: -1;
        visibility: hidden;
      }
      &.open {
        .toggle-children {
          &::before {
            transform: rotate(-135deg) translateY(-50%);
            margin-left: 4px;
          }
        }
        .card-link__childs {
          transform: translateY(0);
          opacity: 1;
          height: auto;
          z-index: 1;
          visibility: visible;
        }
      }
    }
    .toggle-children {
      position: relative;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      gap: 8px;
      &::before {
        content: "";
        order: 2;
        transform: rotate(45deg) translateY(-50%);
        width: 10px;
        height: 10px;
        display: inline;
        transition: all 0.25s;
        border-width: 2px;
        border-color: white;
        border-style: solid;
        border-top: none;
        border-left: none;
      }
    }
  }
}
