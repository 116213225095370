// Twisterbingo variables
$white: #FFF;
$black: #000;
$blue: #000;
$blue-rgb: #000;
$peach: #ffc092;

$primary-color: $black; 
$primary-color-100: #b0e9ff; 
$primary-color-200: #7ffff9; 
$primary-color-300: #4dc4ff;
$primary-color-400: $black !default;
$primary-color-500: #0090ff; 
$primary-color-600: #04b300;
$primary-color-800: #004d00;
$primary-color-900: #001b00; 

$primary-filter-400: brightness(0) saturate(100%) invert(75%) sepia(81%) saturate(434%) hue-rotate(58deg) brightness(102%) contrast(101%);

$secondary-color: #f5811e;
$secondary-color-100: #d9d9d9;
$secondary-color-200: #bfbfbf;
$secondary-color-300: #a6a6a6;
$secondary-color-400: #8c8c8c;
$secondary-color-500: #737373;
$secondary-color-600: #595959;
$secondary-color-700: #404040;
$secondary-color-800: #262626;
$secondary-color-850: #1a1b1f;
$secondary-color-900: #0d0c0d;

$btn-primary: $blue;
$btn-primary-hover: $black;
$btn-primary-shadow: $primary-color-300;

$btn-wide: 160px;

$background-default: $peach;
$font-color: $white;

$brand-color: $background-default;

// Override bootstrap
:root {
    --bs-success-rgb: 	#{$blue-rgb};
	--bs-gray: 			#{$secondary-color-700};
    --bs-gray-dark: 	#{$secondary-color};
	--bs-gray-100:  	#{$secondary-color-100};
    --bs-gray-200: 		#{$secondary-color-200};
    --bs-gray-300: 		#{$secondary-color-300};
    --bs-gray-400: 		#{$secondary-color-400};
    --bs-gray-500: 		#{$secondary-color-500};
    --bs-gray-600: 		#{$secondary-color-600};
    --bs-gray-700: 		#{$secondary-color-700};
    --bs-gray-800: 		#{$secondary-color-800};
    --bs-gray-900: 		#{$secondary-color-900};
	--bs-primary: 		#{$primary-color};
    --bs-secondary: 	#{$secondary-color};
}

$offcanvas-horizontal-width: 335px;
$accordion-button-color: $btn-primary;
$accordion-icon-color: $btn-primary;
$accordion-icon-active-color: $btn-primary;
$accordion-button-active-color: shade-color($btn-primary, 10%);

$position-values: (
	0: 0,
	8: 8%,
	10: 10%,
	50: 50%,
	70: 70%,
	75: 75%,
	83: 83%,
	85: 85%,
	90: 90%,
	100: 100%
) !default;

// transition style
$default-transition-time: .35s;
$default-transition-function: cubic-bezier(0.42, 0, 0.58, 1);

// size

$hero-size: 23rem;

