.pagination {
  .page-item {
    .page-link {
      background-color: #fff000 !important;
      color: #000000 !important;
      border-color: #fff000 !important;
      &:hover {
        background-color: #a89e00 !important;
        color: #ffffff !important;
        border-color: #a89e00 !important;
        background-image: none !important;
        &::after,
        &::before {
          content: none;
        }
      }
    }
    &.active {
      .page-link {
        background-color: #f5811e !important;
        border-color: #f5811e !important;
        color: #ffffff !important;
      }
    }
  }
}
