.terms {
  text-align: left;
  .fs-18 {
    font-size: 18px;
  }
  p,
  ul,
  li,
  ol,
  li {
    font-size: 16px;
    text-align: left;
  }
  .bold {
    font-weight: bold;
  }
}
