/// Mixin to manage responsive breakpoints
@mixin breakpoint($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
      @media #{inspect(map-get($breakpoints, $breakpoint))} {
        @content;
      }
    }
  }
  
  $breakpoints: (
    'xs': ( max-width: 540px ), // not BS5 standard
    'sm': ( min-width: 576px ),
    'md': ( min-width: 768px ),
    'md-max': (max-width: 768px ), // not BS5 standard
    'lg': ( min-width: 992px ),
    'lg-max': ( max-width: 992px ), // not BS5 standard
    'xl': ( min-width: 1200px ),
    'xxl':( min-width: 1400px )
  );
  
  // usage
  body {
    @include breakpoint('sm') {
        // style 
    }
  }
  