@use "sass:math";

// vars
$icon-mob-size: 25px;
$sidebar-top-lg: 90px;
$sidebar-top-sm: 85px;

.card {
  &-body {
    color: white;
    h2 {
      font-size: 1rem;
      color: white;
    }
  }

  &-list {
    list-style: none;
    margin: 0 0 1.5rem 0;
    padding: 0;
    li {
      line-height: 2;
    }
  }

  &-link {
    color: white;
    position: relative;
    letter-spacing: -0.1px;
    font-weight: 500;
    &::after {
      content: "";
      position: absolute;
      display: block;
      border-bottom: 2px solid $primary-color;
      width: 15px;
      left: 0;
      bottom: 0;
      transition: all 0.25s ease;
    }
    &:hover {
      color: $primary-color;
      &::after {
        width: 100%;
      }
    }
  }

  &-nav {
    top: $sidebar-top-sm;
    @include breakpoint("lg-max") {
      background-color: rgba($secondary-color-850, 1);
      position: fixed;
      width: 100vw;
      left: 100%;
      z-index: 10;
      border-radius: 0;
      overflow: auto;
      height: 90vh;
      padding-bottom: 1rem;
      transition-property: left;
      transition-duration: $default-transition-time;
      transition-timing-function: $default-transition-function;
    }
    @include breakpoint("md") {
      top: $sidebar-top-lg;
    }
    @include breakpoint("lg") {
      top: auto;
    }
  }
}

.nav-sidebar {
  display: none;
  &:checked {
    ~ .card.card-nav {
      left: 0;
    }
    + .c-sidebar-menu {
      .navigation-toggle {
        .navigation-toggle-icon {
          transition-delay: 0s;
          background-color: transparent;
          &::before,
          &::after {
            border-radius: 3px;
            width: 100%;
            height: 3px;
            transition-delay: math.div($default-transition-time, 2);
          }
          &::before {
            transform: translateY(1px) rotate(135deg);
          }
          &::after {
            transform: translateY(-7px) rotate(-135deg);
          }
        }
      }
    }
  }
}

.navigation-toggle {
  width: $icon-mob-size;
  height: $icon-mob-size;
  display: block;
  position: relative;
  cursor: pointer;
}

%navigation-toggle-style {
  width: 5px;
  background-color: $secondary-color;
  border-radius: 99rem;
  height: 5px;
  margin: auto;
}

.navigation-toggle-icon {
  margin-top: 8px;
  display: block;
  @extend %navigation-toggle-style;
  transition-property: transform, background-color, width, height;
  transition-duration: $default-transition-time;
  transition-timing-function: $default-transition-function;
  transition-delay: math.div($default-transition-time, 2);
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    @extend %navigation-toggle-style;
    transition-property: transform, background-color, width, height;
    transition-duration: $default-transition-time;
    transition-timing-function: $default-transition-function;
    transition-delay: 0s;
    transform-origin: center;
  }
  &::before {
    top: 7px;
  }
  &::after {
    bottom: 6px;
  }
}

.c-sidebar-menu {
  z-index: 11;
  position: fixed;
  top: $sidebar-top-sm;
  right: 0;
  background: $primary-color;
  padding: 0.5rem;
  border-radius: 0 0 0 1rem;
  @include breakpoint("md") {
    top: $sidebar-top-lg;
  }
  @include breakpoint("lg") {
    display: none;
  }
}

.container.card {
  .row {
    h1 {
      margin: -1rem auto 2rem auto;
      text-align: center;
      font-weight: 500;

      small {
        vertical-align: middle;
      }
    }
  }
}

.card-body {
  input {
  }
  .table {
    overflow-x: auto;
    min-width: 100%;
  }
  table {
    overflow-y: auto;
    max-height: 12.5rem;
    display: block;
    th {
      min-width: 120px;
    }
  }
}

.card > img.card-img {
  max-height: 300px;
}
