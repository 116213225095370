#wrapper{
	padding:10px;
	width:500px;
	margin:0 auto;
	@media (max-width: 768px) {
    		width: 100%;
  	}
}

p{
	/*color:#fff;*/
	/*padding:5px 0;*/
	text-align:center;
	vertical-align: middle;
}
.nav{}
	
#wrapper a{
	color:#e8914c;
	text-decoration:none;
	border-bottom:1px dotted #e8914c;
}

#wrapper a:hover{
	color:#eab209;
	border-bottom:1px dotted #eab209;
}

#vertical-ticker{
	background: rgba(0, 0, 0, 1);
	height:390px;
	overflow:hidden;
	margin:0; padding:0;
	padding-bottom:15px;
}

#vertical-ticker li{
	width: 100%;
	height: 100px;
	align-items: center;
	display:block;
	background:#170038;
	padding-top:32px;
	border-bottom: 10px solid rgba(0, 0, 0, 0.4);
	background-image: url("../images/winnerbg-480x90.png");
  	background-repeat: no-repeat;
	@media (max-width: 768px) {
		background-image: url("../images/winnerbg-328x90.png");
  		background-repeat: no-repeat;
		background-size: 100%;
  	}

	color:#fff;
	text-align:center;
	font-size:20px;
	font-weight:bold;
	font-family: "Montserrat", sans-serif;;
	font-style: normal;
}






